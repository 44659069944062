import productItem from "./const/productItem";
import gql from 'graphql-tag';

export default gql`
  query($filter: ProductAttributeFilterInput) {
  products(filter: $filter) {
      items {
          id
          uid
          crosssell_products ${productItem}
  }
}
}
`;
