import gql from 'graphql-tag';
import labels from "./const/labels";

export default gql`
  query getPageBuilderProductsLabels($source: String = "latest", $order_by: String = "newestfirst", $pageSize: Int = 1, $conditions: String) {
    magezonProducts(source: $source, order_by: $order_by, pageSize: $pageSize, conditions: $conditions) {
      items {
        id
        categories {
          id
        }
        ${labels}
      }
    }
  }
`;
