export default `
  query customerOrders($currentPage: Int = 1, $filter: CustomerOrdersFilterInput = {}, $pageSize: Int = 10) {
    customer {
      orders(currentPage: $currentPage, filter: $filter, pageSize: $pageSize, sortOrder: DESC) {
        items {
          shipments
          {
            id
            shipment_url
            number
            tracking
            {
              carrier
              number
              title
            }
            items
            {
              id
              product_name
              order_item
              {
                product_name
                product_sku
                selected_options
                {
                  label
                  value
                }
              }
              product_sale_price
              {
                currency
                value
              }
              price_incl_tax {
                value
              }
              product_sku
              quantity_shipped
            }
          }
          credit_memos
          {
            id
            number
            items
            {
              id
              order_item
              {
                discounts
                {
                  amount
                  {
                    currency
                    value
                  }
                  label
                }
                entered_options
                {
                  label
                  value
                }
                id
                product_name
                product_sale_price
                {
                  currency
                  value
                }
                price_incl_tax {
                  value
                }
                product_sku
                product_type
                product_url_key
                quantity_canceled
                quantity_invoiced
                quantity_ordered
                quantity_refunded
                quantity_returned
                quantity_shipped
                selected_options
                {
                  label
                  value
                }
                status
              }
              product_name
              product_sku
              product_sale_price
              {
                currency
                value
              }
              price_incl_tax {
                value
              }
              discounts
              {
                amount
                {
                  currency
                  value
                }
              }
              quantity_refunded
            }
            total
            {
              subtotal
              {
                currency
                value
              }
              subtotal_incl_tax {
                  value
              }
              discounts
              {
                amount
                {
                  currency
                  value
                }
              }
              total_tax
              {
                currency
                value
              }
              taxes {
                amount {
                  value
                  currency
                }
                rate
                title
              }
              grand_total
              {
                currency
                value
              }
              base_grand_total
              {
                currency
                value
              }
              total_shipping
              {
                currency
                value
              }
              shipping_handling
              {
                amount_excluding_tax
                {
                  currency
                  value
                }
                amount_including_tax
                {
                  currency
                  value
                }
                discounts
                {
                  amount
                  {
                    currency
                    value
                  }
                }
                taxes
                {
                  amount
                  {
                    currency
                    value
                  }
                  rate
                  title
                }
                total_amount
                {
                  currency
                  value
                }
              }
              adjustment
              {
                currency
                value
              }
            }
            credit_memo_pdf
          }
          shipping_address
          {
            city
            country_code
            firstname
            lastname
            postcode
            prefix
            region
            street
            suffix
            telephone
          }
          billing_address
          {
            city
            country_code
            firstname
            lastname
            postcode
            prefix
            region
            street
            suffix
            telephone
          }
        }
      }
    }
  }
`;
