import productItem from "./const/productItem";
import gql from 'graphql-tag';

export default gql`
  query {
    bestSellers {
        items ${productItem}
    }
  }
`;
