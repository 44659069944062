import gql from 'graphql-tag';
import labels from "./const/labels";
export default gql`
  query upsellProductsReviews(
    $search: String = ""
    $filter: ProductAttributeFilterInput
    $pageSize: Int = 10
    $currentPage: Int = 1
    $sort: ProductAttributeSortInput,
    $updated: String
  ) {
    products(
      search: $search
      filter: $filter
      sort: $sort
      pageSize: $pageSize
      currentPage: $currentPage,
      updated: $updated
    ){
      items {
        upsell_products {
          ${labels}
        }
        uid
        id
        categories {
          id
        }
      }
    }
  }
`;
