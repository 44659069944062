import gql from 'graphql-tag';

export default gql`
  query productReviewOutOfStock($pageSize: Int = 10, $currentPage: Int = 1, $url: String!) {
    route(url: $url) {
      ... on ProductInterface {
        id
        rating_summary
        review_count
        reviews(pageSize: $pageSize, currentPage: $currentPage) {
          items {
            created_at
            average_rating
            ratings_breakdown {
              name
              value
            }
            product {
              name
              uid
            }
            nickname
            summary
            text
          }
          page_info {
            current_page
            page_size
            total_pages
          }
        }
      }
    }
  }
`;
