import gql from 'graphql-tag';
import wishlistEntryData from "./const/wishlistEntryData";

export default gql`
  mutation removeProductsFromWishlist($id: ID!, $items: [ID!]!) {
    removeProductsFromWishlist(wishlistId: $id, wishlistItemsIds: $items) {
      wishlist {
        ${wishlistEntryData}
      }
    }
  }
`;
