import {useUiNotification} from "../../useUiNotification";
import {useContext} from "@nuxtjs/composition-api";

const useNotifications = () => {
  const {send: sendNotification} = useUiNotification();
  const {i18n} = useContext();

  const getAllErrors = (errors) => {
    return Array.isArray(errors) ? errors.map((e) => e.message).join(',') : errors.toString() as string;
  }

  const sendMessage = (title, message, error = true) => {
    const id = title.replaceAll(' ', '_').toLowerCase();
    sendNotification({
      icon: error ? 'error' : 'check',
      id: Symbol(id),
      message: i18n.t(message) as string,
      persist: false,
      title: i18n.t(title) as string,
      type: error ? 'danger' : 'success',
    });
  }

  return {
    getAllErrors,
    sendMessage
  }
};

export default useNotifications;
