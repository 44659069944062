import gql from 'graphql-tag';

export default gql`
  query productReview($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput, $updated: String) {
    products(search: $search, filter: $filter, sort: $sort, updated: $updated) {
      items {
        rating_summary
        review_count
        reviews(pageSize: $pageSize, currentPage: $currentPage) {
          items {
            created_at
            average_rating
            ratings_breakdown {
              name
              value
            }
            product {
              name
              uid
            }
            nickname
            summary
            text
          }
          page_info {
            current_page
            page_size
            total_pages
          }
        }
      }
    }
  }
`;
