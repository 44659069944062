import wishlistEntryData from "./const/wishlistEntryData";

export default `
  query guest($customerId: ID!){
    guest(customerId: $customerId) {
      wishlist {
        ${wishlistEntryData}
      }
    }
  }
`;
