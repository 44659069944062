/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
import productItem from "./const/productItem";
import gql from 'graphql-tag';

export default gql`
  query getFacetData($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput, $updated: String) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort, updated: $updated) {
      items ${productItem}
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
      mw_seo_category_data {
        meta_title
        meta_description
        meta_keywords
        description
        category_seo_name
      }
    }
  }
`;
