import gql from 'graphql-tag';

export default gql`
  query($filter: ProductAttributeFilterInput) {
  products(filter: $filter) {
      items {
          id
          uid
          categories {
            id
          }
          related_products {
           rating_summary
           review_count
          }
  }
}
}
`;
