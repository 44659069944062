import {useConfigStore} from "~/stores/config";

const useTheme = () => {
  const defaultTheme = 'palmers';

  const configStore = useConfigStore();
  const {storeConfig} = configStore;

  const themas = {
    4: 'palmers',
    6: 'palmers_new'
  }

  const getThemeById = (id) => {
    return themas?.[id];
  }

  const getCurrentTheme = () => {
    //@ts-ignore
    const {design_theme_theme_id} = storeConfig

    if(!design_theme_theme_id) return false;

    return themas?.[parseInt(design_theme_theme_id)] || defaultTheme;
  }

  return {
    getThemeById,
    themas,
    getCurrentTheme
  }
};

export default useTheme;
