import gql from 'graphql-tag';
import wishlistEntryData from "./const/wishlistEntryData";

export default gql`
  mutation updateProductsInWishlist($wishlistId: ID!, $items: [WishlistItemUpdateInput!]!, $customerToken: ID!) {
    updateProductsInWishlist(
      wishlistId: $wishlistId,
      wishlistItems: $items,
      customerToken: $customerToken
    ) {
      wishlist {
        ${wishlistEntryData}
      }
    }
  }
`;
