import gql from 'graphql-tag';

export default gql`
  mutation submitCustomForm($form_data: String){
    amCustomFormSubmit(input: {form_data: $form_data}) {
      status
      message
    }
  }
`;
