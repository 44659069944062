<template>
  <picture v-if="pictureData && getSrcSet(pictureDataJson) && pictureDataJson && !isLocalHost(pictureData.desktop)">
    <source
      v-for="source in getSources(pictureDataJson, additionalSources)"
      :srcset="getSrcSetSource(source)"
      :media="source['media']" :type="source['type']"
    />
    <NuxtImage
      :src="getSrcSet(pictureDataJson)"
      :alt="pictureData.alt"
      :loading="((pictureDataJson.lazy || pictureData.isLazy) && !pictureData.isEager)  ? 'lazy' : ''"
      :class="customClass"
      @click="$emit('imageClick')"
      :width="pictureDataJson.width"
      :height="pictureDataJson.height"
      placeholder="true"
      class="image-helper"
      ref="image"
    />
  </picture>
  <div v-else>
    <picture class="local-picture">
      <img class="image-helper" loading="lazy" :src="pictureData.desktop" alt="loc">
    </picture>
  </div>
</template>

<script>
import {defineComponent, ref} from "@nuxtjs/composition-api";
import pictureDataJson from '~/components/Palmers/BaseComponents/Picture/pictureData.json';
import NuxtImage from "@nuxt/image/dist/runtime/components/nuxt-img";
export default defineComponent({
  name: 'PictureItem',
  components: {NuxtImage},
  data: () => ({
    q: 90,
    fm: 'jpg'
  }),
  props: {
    pictureData: {
      type: Object,
      require: true
    },
    customClass: {
      type: String
    }
  },
  setup(props) {
    const additionalSources = ref([]);
    let mobileMedia = "(max-width: 767px)";
    let json = pictureDataJson[props.pictureData.type];

    const isMobileSource = function (source) {
      if (source?.media === mobileMedia) {
        return source;
      }

      return false;
    };

    const getMobileSource = function (sources) {
      return sources.filter((source) => {
        return isMobileSource(source) ? isMobileSource(source) : false;
      })[0];
    };

    if (json) {
      additionalSources.value = [
        {
          "type": "image/webp",
          "format": "webp",
          "width": json.width,
          "height": json.height,
        }
      ];


      if (props.pictureData.mobile) {
        let mobileWidth = getMobileSource(json.sources).width;
        let mobileHeight = getMobileSource(json.sources).height;

        additionalSources.value.push({
          "type": "image/webp",
          "format": "webp",
          "media": "(max-width: 767px)",
          "width": mobileWidth,
          "height": mobileHeight,
        });
      }
    }

    return {
      pictureDataJson: json,
      additionalSources,
      isMobileSource
    }
  },
  methods: {
    getSrcSet(source) {
      let url = this.pictureData.desktop;

      if (this.isMobileSource(source) && this.pictureData.mobile) {
        url = this.pictureData.mobile;
      }

      return url;
    },
    getSrcSetSource(source) {
      let url = this.getSrcSet(source);

      const ignoreModifiers = ['media', 'lazy', 'sources', 'type', 'src'];

      const modifiers = JSON.parse(JSON.stringify(source));

      const modifiersKeys = Object.keys(modifiers);
      for (let m = 0; m < modifiersKeys.length; m++) {
        if(ignoreModifiers.includes(modifiersKeys[m])) {
          delete modifiers[modifiersKeys[m]];
        }
      }

      const data = this.$img.getImage(url, {
        modifiers: modifiers
      });

      return data?.url;
    },
    isLocalHost(url) {
      return (url.indexOf('loc')) > 0
    },
    getAttrs(pictureDataJson) {
      const attrs = {};

      if (pictureDataJson.lazy) {
        attrs['loading'] = 'lazy';
      }

      return attrs;
    },
    getSources(pictureDataJson, additionalSources) {
      let sources = [];

      if (pictureDataJson?.sources) {
        sources = pictureDataJson?.sources;
      }

      if (additionalSources) {
        sources = [
          ...sources,
          ...additionalSources
        ]
      }

      for (let s = 0; s < sources.length; s++) {
        sources[s]['src'] = this.getSrcSet(sources[s]);
      }

      return sources;
    },
    onLoadPicture() {
      this.$emit('onLoad', this.$refs?.image);
    }
  },
  mounted() {
    const image = this.$refs?.image;
    if (image) {
      image.onload = () => {
        this.onLoadPicture();
      }
    }
    this.$emit('loaded', true);
  }
});
</script>


<style>
.category-product-item .product-item-picture > a img.image-helper{
  aspect-ratio: 1 / 1.3332;
}
</style>
