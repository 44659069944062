import gql from 'graphql-tag';
import wishlistEntryData from "./const/wishlistEntryData";

export default gql`
  mutation guestUpdateProductsInWishlist($customerId: ID!, $items: [WishlistItemUpdateInput!]!) {
    guestUpdateProductsInWishlist(
      customerId: $customerId,
      wishlistItems: $items
    ) {
      wishlist {
        ${wishlistEntryData}
      }
    }
  }
`;
