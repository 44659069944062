import Vue from "vue";
import PictureItem from "~/components/Palmers/BaseComponents/Picture/PictureItem.vue";

const useCmsContent = () => {
  const pwaReplaceContentHtml = (htmlToReplace: any, id) => {
    if (!htmlToReplace) return htmlToReplace;

    if (typeof htmlToReplace !== 'string') return htmlToReplace;

    if (!htmlToReplace.indexOf(id)) return htmlToReplace;


    //@ts-ignore
    htmlToReplace = htmlToReplace.replaceAll(/<a(.+)href="(.+)">(.+)<\/a>/g, (attr1, attr2, attr3, attr4) => {
      if (attr3.indexOf('http') > -1) return attr1;
      if (attr3.indexOf('#') > -1) return attr1;

      return `<nuxt-link ${attr2} :to='localePath("${attr3}")'>${attr4}</nuxt-link>`
    });

    let imgReplaced = false;

    //@ts-ignore
    htmlToReplace = htmlToReplace.replaceAll(/<img(.+)src="(.+)" alt="(.*)" width="(.+)" height="(.+)">/g, (attr1, attr2, attr3, attr4, attr5, attr6) => {
      imgReplaced = true;
      return `<PictureItem ${attr2} :picture-data="{desktop: '${attr3}', type: '${attr4}', alt: '${attr4}'}" width="${attr5}" height="${attr6}" />`;
    });

    if (!imgReplaced) {
      //@ts-ignore
      htmlToReplace = htmlToReplace.replaceAll(/<img(.+)src="(.+)" alt="(.*)">/g, (attr1, attr2, attr3, attr4) => {
        imgReplaced = true;
        return `<PictureItem ${attr2} :picture-data="{desktop: '${attr3}', type: '${attr4}', alt: '${attr4}'}" />`;
      });
    }

    imgReplaced = false;

    return `<div id="${id}">${htmlToReplace}</div>`;
  }


  const pwaReformat = (content, id) => {
    if (!id || !content) return content;

    let reformat = '';

    if (typeof content === 'string' || content instanceof String) {
      content = pwaReplaceContentHtml(content, id);
    } else if (content?.content) {
      content.content = pwaReplaceContentHtml(content.content, id);
    }

    reformat = content;

    return reformat;
  }

  return {
    pwaReformat
  }
};

export default useCmsContent;
