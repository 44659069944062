import gql from 'graphql-tag';

export default gql`
  query cart($cartId: String!) {
    cart(cart_id:$cartId) {
      free_shipping_bar {
        id
        action_clickable
        action_link
        car_icon_visible
        closeable
        name
        status
        goal
        goal_source
        priority
        position
        pages
        text_font
        text_size
        text_color
        extra_color
        background_color
        custom_style
        labels {
          init_message
          progress_message
          achieved_message
          terms_message
        }
      }
    }
  }
`;
