import {reactive} from "@nuxtjs/composition-api";
import getAllAttributes from "~/customQueries/queries/getAllAttributes";

const validateData = reactive({
  attributes: ['page', 'sort'],
  category_uid: ''
});

const useValidateAttribute = () => {
  const validate = (query) => {
    for (const q in Object.keys(query)) {
      if(!validateData.attributes.includes(q)) delete query[q];
    }

    return query
  }

  const setAttributes = async (context, routeData) => {
    if(routeData?.uid) {
      if(routeData.uid !== validateData.category_uid || validateData.attributes.length === 2) {
        const {data} = await context.$vsf.$magento.api.customQuery({
          query: getAllAttributes,
          queryVariables: {
            filter: {
              category_uid: {
                eq: routeData.uid
              }
            }
          }
        });

        const attributes = data?.['products']?.aggregations;

        if(attributes) {
          for(let a = 0; a < attributes.length; a++) {
            if(!validateData.attributes.includes(attributes[a].attribute_code)) validateData.attributes.push(attributes[a].attribute_code)
          }
        }

        validateData.category_uid = routeData.uid;
      }
    }
  }

  return {
    validate,
    setAttributes
  }
};

export default useValidateAttribute;
