import gql from 'graphql-tag';

export default gql`
  query getPageBuilderProductsReviews($source: String = "latest", $order_by: String = "newestfirst", $pageSize: Int = 1, $conditions: String) {
    magezonProducts(source: $source, order_by: $order_by, pageSize: $pageSize, conditions: $conditions) {
      items {
        id
        uid
        categories {
          id
        }
        rating_summary
        review_count
      }
    }
  }
`;
