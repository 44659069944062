export default `
  query computopCwAutorize($order_increment_id: String!, $form_values: [String]) {
    computopCwAutorize(order_increment_id: $order_increment_id, form_values: $form_values) {
      formActionUrl
      hiddenFormFields {
        key
        value
      }
      redirectionUrl
    }
  }
`;
