import gql from 'graphql-tag';

export default gql`
  query getProductAttributeList {
    productAttributeList {
      attribute_code
      attribute_options {
        label
        value
      }
      attribute_type
      entity_type
      input_type
      storefront_properties {
        position
        use_in_layered_navigation
        use_in_product_listing
        use_in_search_results_layered_navigation
        visible_on_catalog_pages
      }
    }
  }
`;
