import {computed, reactive, ref, useContext} from "@nuxtjs/composition-api";
import {useWishlistStore} from "~/modules/wishlist/store/wishlistStore";
import {Logger} from "~/helpers/logger";
import {UseWishlistErrors, UseWishlistRemoveItemParams} from "~/modules/wishlist/composables/useWishlist";
import guestCreateCustomerId from '~/customQueries/queries/guestCreateCustomerId.gql';
import {Wishlist} from "~/modules/GraphQL/types";
import {findItemOnWishlist} from "~/modules/wishlist/helpers/findItemOnWishlist";

const state = reactive({
  loading: false,
  itemInProgress: {}
});

const useGuestWishlist = () => {
  const {$vsf, app} = useContext();
  const loading = computed(() => state.loading);
  const itemInProgress = computed(() => state.itemInProgress);
  const calculateWishlistTotal = (wishlists: Wishlist[]) => wishlists.reduce((acc, current) => acc + (current?.items_count || 0), 0);
  const error = ref<UseWishlistErrors>({
    addItem: null,
    removeItem: null,
    load: null,
    clear: null,
    loadItemsCount: null,
    afterAddingWishlistItemToCart: null,
  });

  const wishlistStore = useWishlistStore();

  const load = async () => {
    Logger.debug('useGuestWishlist/load');

    const guestId = app.$cookies.get('guest_customer_id');

    if (guestId) {
      wishlistStore.$patch((state) => {
        state.guestId = guestId
      });
    }

    try {
      state.loading = true;
      Logger.debug('[Magento Storefront]: useGuestWishlist.load params->');

      if (!wishlistStore.guestId) {
        const {data} = (await $vsf.$magento.api.customMutation({
          mutation: guestCreateCustomerId,
          mutationVariables: {}
        }));

        const guestIdData = data?.['guestCreateCustomerId']?.customer_id;
        const guestDataLifeTime = data?.['guestCreateCustomerId']?.cookie_lifetime;

        if (guestIdData && guestDataLifeTime) {
          app.$cookies.set('guest_customer_id', guestIdData, {
            path: '/',
            maxAge: parseInt(guestDataLifeTime) * 86400
          })
        }

        wishlistStore.$patch((state) => {
          state.guestId = data?.['guestCreateCustomerId']?.customer_id || ''
        });
      } else {
        //@ts-ignore
        const {data} = await $vsf.$magento.api.guestLoadWishlist(wishlistStore.guestId, {});


        wishlistStore.wishlist = data?.['guest'].wishlist || {};
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useGuestWishlist/load', err);
    } finally {
      state.loading = false;
    }

    return wishlistStore.wishlist;
  };

  const loadItemsCount = async (): Promise<number | null> => {
    Logger.debug('useGuestWishlist/wishlistItemsCount');
    let itemsCount: number | null = null;

    try {
      state.loading = true;
      error.value.loadItemsCount = null;

      if (!wishlistStore.guestId) {
        await load();
      }else {
        //@ts-ignore
        const {data} = wishlistStore.wishlist.length ? {data: wishlistStore.wishlist} : await $vsf.$magento.api.guestLoadItemsCountWishlist(wishlistStore.guestId, {});

        Logger.debug('[Result]:', {data});
        const loadedWishlist: Wishlist[] = [data['guest']?.wishlist] || [];
        itemsCount = calculateWishlistTotal(loadedWishlist);
        wishlistStore.$patch((state) => {
          state.wishlist.items_count = itemsCount;
        });
      }

    } catch (err) {
      error.value.loadItemsCount = err;
      Logger.error('useGuestWishlist/wishlistItemsCount', err);
    } finally {
      state.loading = false;
    }

    return itemsCount;
  };

  // eslint-disable-next-line consistent-return
  const addItem = async ({product, productConfiguration}) => {
    Logger.debug('useGuestWishlist/addItem', product);

    try {
      state.loading = true;
      state.itemInProgress = product;
      Logger.debug('[Magento Storefront]: useWishlist.addItem params->', {
        currentWishlist: wishlistStore.wishlist,
        product
      });

      if (!wishlistStore.guestId) {
        await load();
      }

      const itemOnWishlist = findItemOnWishlist(wishlistStore.wishlist, product);

      if (itemOnWishlist) {
        return;
      }

      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      switch (product.__typename) {
        case 'VirtualProduct':
        case 'DownloadableProduct':
        case 'GroupedProduct':
        case 'GiftCard':
        case 'SimpleProduct': {

          //@ts-ignore
          const {data} = await $vsf.$magento.api.guestAddProductsToWishlist({
            id: wishlistStore.guestId, items: [{
              sku: product.sku,
              quantity: 1,
            }]
          }, {});

          Logger.debug('[Result]:', {data});

          wishlistStore.$patch((state) => {
            state.wishlist = data['guestAddProductsToWishlist']?.wishlist || {};
          });

          break;
        }
        case 'ConfigurableProduct': {
          const selectedOptions = Object.values(productConfiguration as object);
          //@ts-ignore
          const {data} = await $vsf.$magento.api.guestAddProductsToWishlist({
            id: wishlistStore.guestId, items: [{
              sku: product.configurable_product_options_selection?.variant?.sku || product.sku,
              quantity: 1,
              parent_sku: product.sku,
              selected_options: selectedOptions
            }]
          }, {});

          Logger.debug('[Result]:', {data: data['guestAddProductsToWishlist']?.wishlist || {}});

          wishlistStore.$patch((state) => {
            state.wishlist = data['guestAddProductsToWishlist']?.wishlist || {};
          });

          break;
        }
        case 'BundleProduct': {
          //@ts-ignore
          const {data} = await $vsf.$magento.api.guestAddProductsToWishlist({
            id: wishlistStore.guestId, items: [{
              sku: product.sku,
              quantity: 1,
              entered_options: [],
            }]
          }, {});

          Logger.debug('[Result]:', {data: data['guestAddProductsToWishlist']?.wishlist || {}});

          wishlistStore.$patch((state) => {
            state.wishlist = data['guestAddProductsToWishlist']?.wishlist || {};
          });

          break;
        }
        default:
          // @ts-ignore
          // eslint-disable-next-line no-underscore-dangle
          Logger.error(`Product Type ${product.__typename} not supported in add to wishlist yet`);
      }
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useGuestWishlist/addItem', err);
    } finally {
      state.loading = false;
    }
  };

  const removeItem = async ({product}: UseWishlistRemoveItemParams) => {
    Logger.debug('useGuestWishlist/removeItem', product);

    try {
      state.loading = true;
      state.itemInProgress = product;
      Logger.debug('[Magento Storefront]: useWishlist.removeItem params->', {
        currentWishlist: wishlistStore.wishlist,
        product,
      });

      const itemOnWishlist = findItemOnWishlist(wishlistStore.wishlist, product);

      //@ts-ignore
      const {data} = await $vsf.$magento.api.guestRemoveProductsFromWishlist({id: wishlistStore.guestId, items: [itemOnWishlist.id]}, {});

      Logger.debug('[Result]:', {data});
      error.value.removeItem = null;
      wishlistStore.$patch((state) => {
        state.wishlist = data['guestRemoveProductsFromWishlist']?.wishlist || {};
      });
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useGuestWishlist/removeItem', err);
    } finally {
      state.loading = false;
    }
  };

  const removeItems = async ({products}) => {
    Logger.debug('useGuestWishlist/removeItem', 'mass');

    try {
      state.loading = true;
      Logger.debug('[Magento Storefront]: useWishlist.removeItem params->', {
        currentWishlist: wishlistStore.wishlist
      });

      const itemsInWishlist = [];

      for(let p = 0; p < products.length; p++) {
        const product = products[p].product;
        itemsInWishlist.push(findItemOnWishlist(wishlistStore.wishlist, product)?.id);
      }

      //@ts-ignore
      const {data} = await $vsf.$magento.api.guestRemoveProductsFromWishlist({id: wishlistStore.guestId, items: itemsInWishlist}, {});

      Logger.debug('[Result]:', {data});
      error.value.removeItem = null;
      wishlistStore.$patch((state) => {
        state.wishlist = data['guestRemoveProductsFromWishlist']?.wishlist || {};
      });
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useGuestWishlist/removeItem', err);
    } finally {
      state.loading = false;
    }
  };

  return {
    loading,
    load,
    error,
    loadItemsCount,
    addItem,
    removeItem,
    removeItems,
    itemInProgress
  }
};

export default useGuestWishlist;
