/* eslint-disable  */

export default {

  "Item added to cart": "Artikel in den Warenkorb gelegt",
  "View Cart": "Warenkorb",
  "Departments": "Abteilungen",
  "Description": "Beschreibung",
  "Gender": "Geschlecht",
  "Gender:": "Geschlecht:",
  "Details and status orders": "Überprüfen Sie die Details und den Status Ihrer Bestellungen im Online-Shop. Sie können Ihre Bestellung auch stornieren oder eine Rücksendung anfordern. ",
  "Discount": "Rabatt",
  "Discount:": "Rabatt:",
  "Edit delivery address": "Adresse ändern",
  "The user has been successfully registered.": "Ihre Anmeldung zum Palmers Club war erfolgreich.",
  "Row total": "Zeilensumme",
  "Product name": "Produktname",
  "Refund": "Rückerstattung",
  "Product Information": "Produktinformationen",
  "Maintain properly": "Richtig pflegen",
  "Print Refund": "Rückerstattung drucken",
  "Done": "Fertig",
  "Download": "Herunterladen",
  "Download all": "Alle herunterladen",
  "Edit": "Bearbeiten",
  "Email": "E-Mail",
  "Check availability in store": "Im Geschäft suchen",
  "Discount codes": "Rabattcodes",
  "Street and Apartment are limited to 47 characters":"Straße und Wohnung sind auf 47 Zeichen begrenzt",
  "Street and Apartment are limited to 46 characters":"Straße und Wohnung sind auf 46 Zeichen begrenzt",
  "Repeat password": "Passwort wiederholen",
  "Email address": "E-Mail-Adresse",
  "Empty": "Ihr Warenkorb ist leer.",
  "Empty bag": "Leerer Warenkorb",
  "Enjoy your free account": "Genießen Sie alle Vorteile Ihres Gratis-Kontos!",
  "Enter promo code": "Geben Sie den Promo-Code ein",
  "Feedback": "Ihr Feedback ist uns wichtig. Lassen Sie uns wissen, wie Ihr Shopping-Erlebnis war.",
  "Feel free to edit": "Aktualisieren Sie Ihre Daten regelmäßig, damit Ihr Konto immer auf dem neuesten Stand ist.",
  "Filters": "Filter",
  "Continue Shopping": "Mit dem Einkaufen fortfahren",
  "Continue shopping": "Mit dem Einkaufen fortfahren",
  "Forgot password?": "Passwort vergessen?",
  "Find out more": "Finde mehr heraus",
  "First Name": "Vorname",
  "Forgot Password": "Wenn Sie Ihr Passwort vergessen haben, können Sie es zurücksetzen.",
  "Forgot Password Modal Email": "E-Mail-Adresse, mit der Sie sich anmelden:",
  "forgotPasswordConfirmation": "Vielen Dank! Wenn ein Konto mit der E-Mail-Adresse registriert ist, finden Sie in Ihrem Posteingang eine Nachricht mit einem Link zum Zurücksetzen des Passworts.",
  "Forgotten password?": "Passwort vergessen?",
  "Go back": "Zurück",
  "Go back shopping": "Zurück einkaufen",
  "Go back to shop": "Zurück zum Einkaufen",
  "Go to checkout": "Zur Kasse gehen",
  "To Checkout": "Zur Kasse",
  "To Checkout - ": "Zur Kasse - ",
  "Go to cart": "Zum Warenkorb gehen",
  "Guarantee": "Garantie",
  "Help": "Hilfe",
  "Help & FAQs": "Hilfe & FAQs",
  "hide": "ausblenden",
  "Home": "Startseite",
  "I agree to": "Ich stimme zu",
  "I confirm subscription": "Ich bestätige das Abonnement",
  "I want to create an account": "Ich möchte ein Konto erstellen",
  "Info after order": "Sie können sich mit E-Mail und definiertem Passwort in Ihrem Konto anmelden vorhin. Überprüfen Sie in Ihrem Konto Ihre Profildaten Transaktionsverlauf, Abonnement für Newsletter bearbeiten.",
  "Instruction1": "Um mich kümmern",
  "Instruction2": "Nur hier für die Pflegehinweise?",
  "Instruction3": "Ja, das haben wir uns gedacht",
  "It was not possible to request a new password, please check the entered email address.": "Es war nicht möglich, ein neues Passwort anzufordern, bitte überprüfen Sie die eingegebene E-Mail-Adresse.",
  "Item": "Artikel",
  "Items": "Artikel",
  "Kidswear": "Kinderkleidung",
  "Last Name": "Nachname",
  "Let’s start now – we’ll help you": "Fangen wir jetzt an - wir helfen Ihnen.",
  "Log into your account": "In Ihr Konto einloggen",
  "Login": "Anmelden",
  "login in to your account": "In Ihr Konto einloggen",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Sieht so aus, als hätten Sie der Tasche noch keine Artikel hinzugefügt. Beginnen Sie mit dem Einkaufen, um es auszufüllen.",
  "Looks like you haven’t added any items to the Wishlist.": "Sie haben noch keine Artikel zur Wunschliste hinzugefügt.",
  "Make an order": "Jetzt zahlungspflichtig bestellen",
  "Manage addresses": "Verwalten Sie alle Ihre Adressen (Arbeitsplatz, Privatadresse, ...)",
  "Manage billing addresses": "Verwalten Sie alle Ihre Rechnungs-Adressen. Auf diese Weise müssen Sie die Rechnungs-Adresse nicht bei jeder Bestellung erneut eingeben.",
  "Manage shipping addresses": "Verwalten Sie alle Ihre Versand-Adressen (Arbeitsplatz, Privatadresse, ...). Auf diese Weise müssen Sie die Versand-Adresse nicht bei jeder Bestellung erneut eingeben.",
  "Match it with": "Kombinieren Sie es mit",
  "Men fashion": "Herrenmode",
  "Menu": "Menü",
  "My billing and shipping address are the same": "Meine Rechnungs- und Versand-Adresse sind identisch.",
  "My Cart": "Mein Warenkorb",
  "New Products": "Neue Produkte",
  "No account": "Sie haben noch kein Konto?",
  "or": "oder",
  "or fill the details below": "oder füllen Sie die nachfolgenden Details aus",
  "Order ID": "Bestell-ID",
  "Order information": "Bestell-Informationen",
  "Order No.": "Bestellnummer",
  "Order summary": "Bestellübersicht",
  "Other products you might like": "Andere Produkte, die Ihnen auch gefallen könnten",
  "Out of stock": "Ausverkauft",
  "Password": "Passwort",
  "Confirm Password": "Passwort bestätigen",
  "Password Changed": "Passwort erfolgreich geändert. Sie können nun zur Startseite zurückkehren und sich anmelden.",
  "Pay for order": "Bestellung bezahlen",
  "Payment": "Zahlung",
  "Payment & Delivery": "Zahlung & Lieferung",
  "Payment Method": "Zahlungsmethode",
  "Payment Methods": "Zahlungsmethoden",
  "Personal details": "Persönliche Daten",
  "Please type your current password to change your email address.": "Bitte geben Sie Ihr aktuelles Passwort ein, um Ihre E-Mail-Adresse zu ändern.",
  "Privacy": "Datenschutz",
  "Privacy Policy": "Datenschutzrichtlinie",
  "Product": "Produkt",
  "Product description": "Produktbeschreibung",
  "Product suggestions": "Produktvorschläge",
  "Products": "Produkte",
  "Products found": "Produkte gefunden",
  "Purchase terms": "Kaufbedingungen",
  "Quality in the details": "Qualität im Detail",
  "Quantity": "Menge",
  "Read all reviews": "Alle Bewertungen lesen",
  "Read and understand": "Ich habe es gelesen und verstanden",
  "Read reviews": "Bewertungen lesen",
  "Register": "Konto",
  "Register now": "Jetzt registrieren",
  "Register today": "Melden Sie sich noch heute an",
  "Remove": "Löschen",
  "Remove Address": "Adresse löschen",
  "Remove from Wishlist": "Von Wunschliste löschen",
  "Repeat Password": "Passwort wiederholen",
  "Reset Password": "Passwort zurücksetzen",
  "Review my order": "Meine Bestellung überprüfen",
  "Same as shipping address": "Wie Versandadresse",
  "Save changes": "Änderungen speichern",
  "Save for later": "Für später speichern",
  "Save Password": "Passwort speichern",
  "Search": "Suche",
  "Search for items": "Nach Artikeln suchen",
  "Search results": "Suchergebnisse",
  "Sections that interest you": "Abschnitte, die Sie interessieren könnten",
  "See all": "Alle anzeigen",
  "See all results": "Alle Ergebnisse anzeigen",
  "See more": "Mehr sehen",
  "Select payment method": "Zahlungsmethode auswählen",
  "Select shipping method": "Versandart auswählen",
  "Send my feedback": "Mein Feedback senden",
  "Set up newsletter": "Melden Sie sich noch heute zum Palmers Newsletter an, um regelmäßig über unsere Kollektion, Club-Aktivitäten und aktionen informiert zu werden. ",
  "Share your look": "Teilen Sie Ihren Look",
  "Shipping": "Versand",
  "Shipping Step": "Kundendaten",
  "Shipping address": "Versandadresse",
  "Shipping details": "Versanddetails",
  "Shipping method": "Versandart",
  "Show":"Anzeigen",
  "Stay logged-in":"Eingeloggt bleiben",
  "show more": "mehr anzeigen",
  "Show on page": "Auf Seite anzeigen",
  "Sign in": "Einloggen",
  "Size guide": "Größentabelle",
  "Sign Up for Newsletter": "Anmeldung zum Newsletter",
  "Sort by": "Sortieren nach",
  "Sort: Default": "Standard",
  "Sort: Name A-Z": "Benennung nach A-Z",
  "Sort: Name Z-A": "Benennung nach Z-A",
  "Sort: Price from high to low": "Preis von hoch bis niedrig",
  "Sort: Price from low to high": "Preis von niedrig bis hoch",
  "Start shopping": "Einkaufen starten",
  "Status": "Status",
  "Price from low to high": "Preis aufsteigend",
  "Price from high to low": "Preis absteigend",
  "Subscribe": "Abonnieren",
  "Subscribe to newsletter": "Anmeldung zum Newsletter",
  "subscribeToNewsletterModalContent": "Wenn Sie sich für den Newsletter angemeldet haben, erhalten Sie spezielle Angebote und Nachrichten von VSF per E-Mail. Wir werdenIhre E-Mail zu keinem Zeitpunkt an Dritte verkaufen oder weitergeben. Bitte beachten Sie unsere.",
  "Subtotal": "Zwischensumme",
  "Subtotal price": "Zwischensumme Preis",
  "Successful placed order": "Sie haben die Bestellung erfolgreich aufgegeben. Sie erhalten eine Bestell-Bestätigungs-E-Mail mit Details Ihrer Bestellung. ",
  "Terms and conditions": "Allgemeine Geschäftsbedingungen",
  "Thank you": "Danke",
  "Thank You Inbox": "Wenn die Nachricht nicht in Ihrem Posteingang ankommt, versuchen Sie es mit einer anderen E-Mail-Adresse, mit der Sie sich möglicherweise registriert haben.",
  "Total": "Gesamt",
  "Complete": "Vollständig",
  "Pending": "Ausstehend",
  "Closed": "Geschlossen",
  "Ordered": "Bestellt",
  "Invoiced": "In Rechnung gestellt",
  "Cancelled": "Abgebrochen",
  "Deleted": "Gelöscht",
  "Delete": "Löschen",
  "Best selling": "Bestseller",
  "Most Viewed": "Am häufigsten angesehen",
  "Most viewed": "Am häufigsten angesehen",
  "Order Total": "Gesamtsumme",
  "Total items": "Gesamtanzahl",
  "Total price": "Gesamtpreis",
  "Update": "Speichern",
  "Club Information": "Clubinformationen",
  "Card type:": "Kartenart:",
  "Difference to the next one:": "Differenz zum nächsten:",
  "Loyalty voucher Open Club card fee:": "Treuegutschein Offene Clubkartengebühr:",
  "Loyalty details will be shown, after You will get your voucher.": " Sie haben keine aktuellen Gutscheine.",
  "Make default Billing address": "Als meine Standardrechnungsadresse verwenden",
  "Make default Shipping address": "Als meine Standardversandadresse verwenden",
  "Update password": "Passwort aktualisieren",
  "Update personal data": "Persönliche Daten aktualisieren",
  "Use your personal data": "Bei Markennamen legen wir großen Wert auf Datenschutzfragen und verpflichten uns, die persönlichen Daten unserer Benutzer zu schützen. Erfahren Sie mehr darüber, wie wir Ihre persönlichen Daten pflegen und verwenden",
  "User Account": "Benutzerkonto",
  "View": "Ansicht",
  "View details": "Details anzeigen",
  "Vue Storefront Next": "Vue Storefront Next",
  "We haven’t found any results for given phrase": "Wir haben keine Ergebnisse für Ihre Suche gefunden",
  "Who we are": "Wer wir sind",
  "Wishlist": "Wunschliste",
  "Women fashion": "Damenmode",
  "You added {product} to your shopping cart.": "Sie haben „{product}“ zu Ihrem Warenkorb hinzugefügt.",
  "You are not authorized, please log in": "Sie sind nicht autorisiert, bitte melden Sie sich an.",
  "You can unsubscribe at any time": "Sie können sich jederzeit abmelden.",
  "You currently have no orders": "Sie haben derzeit keine Bestellungen.",
  "The email field must be a valid email":"Das E-Mail-Feld muss eine gültige E-Mail-Adresse sein.",
  "You haven’t searched for items yet": "Sie haben noch nicht nach Artikeln gesucht.",
  "Your bag is empty": "Ihre Warenkorb ist leer",
  "Your current email address is": "Ihre aktuelle E-Mail-Adresse lautet",
  "Your email": "Ihre E-Mail",
  "You have submitted no reviews": "Sie haben keine Bewertungen abgegeben.",
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":"Die Kontoanmeldung war falsch oder Ihr Konto wurde vorübergehend deaktiviert. Bitte warten Sie und versuchen Sie es später erneut.",
  "Invalid email": "Ungültige E-Mail",
  "SUMMER COLLECTION {year}": "SOMMERKOLLEKTION {year}",
  "Colorful summer dresses are already in store": "Bunte Sommerkleider sind bereits im Store",
  "Learn more": "Mehr erfahren",
  "Dresses": "Kleider",
  "Cocktail & Party": "Cocktailparty",
  "Linen Dresses": "Leinenkleider",
  "T-Shirts": "T-Shirts",
  "Woman": "Frau",
  "Man": "Herr",
  "The Office Life": "Das Büroleben",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Finden Sie atemberaubende Cocktailkleider für Damen und Cocktailkleider für Party-D...c von all Ihren Lieblingsmarken.",
  "Shop now": "Jetzt einkaufen",
  "Summer Sandals": "Sommersandalen",
  "Eco Sandals": "Öko-Sandalen",
  "Subscribe to Newsletters": "Newsletter abonnieren",
  "Palmers Newsletter": "Palmers Newsletter",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Informieren Sie sich über bevorstehende Verkäufe und Veranstaltungen. Erhalten Sie Geschenke und Sonderangebote",
  "Fashion to take away": "Mode zum Mitnehmen",
  "Download our application to your mobile": "Laden Sie unsere Anwendung auf Ihr Handy herunter",
  "Share Your Look": "Teilen Sie Ihren Look",
  "My Account": "Mein Konto",
  "My profile": "Mein Profil",
  "Personal Details": "Persönliche Daten",
  "Addresses details": "Adressendetails",
  "My newsletter": "Mein Newsletter",
  "Log out": "Ausloggen",
  "My reviews": "Meine Bewertungen",
  "Order history": "Bestellverlauf",
  "Order details": "Bestellungsdetails",
  "My wishlist": "Meine Wunschliste",
  "My Wishlist": "Meine Wunschliste",
  "Password change": "Passwortänderung",
  "Personal data": "Persönliche Daten",
  "Your e-mail": "Ihre E-Mail-Adresse",
  "Current Password": "Aktuelles Passwort",
  "You are not authorized, please log in.": "Sie sind nicht autorisiert, bitte melden Sie sich an.",
  "Go To Product": "Zum Produkt",
  "Returns":"Retouren",
  "My orders": "Meine Bestellungen",
  "Add the address": "Fügen Sie die Adresse hinzu",
  "Set as default shipping": "Als Standardversand-Adresse festlegen",
  "Set as default billing": "Als Standardrechnungs-Adresse festlegen",
  "House/Apartment number": "Haus-/Wohnungsnummer",
  "Street Name": "Straßenname",
  "City": "Stadt",
  "State/Province": "Staat/Provinz",
  "Zip-code": "Postleitzahl",
  "Country": "Land",
  "ZIP & City": "PLZ & Ort",
  "ZIP": "PLZ",
  "ZIP City": "Ort",
  "Street": "Straße",
  "House number": "Hausnummer",
  "Door number": "Türnummer",
  "Street & House number": "Straße und Hausnummer",
  "Phone number": "Telefonnummer",
  "Please select a country first": "Bitte wählen Sie zuerst ein Land aus",
  "This field is required": "Dieses Feld ist erforderlich",
  "The field should have at least 2 characters": "Das Feld sollte mindestens 2 Zeichen lang sein",
  "The field should have at least 4 characters": "Das Feld sollte mindestens 4 Zeichen lang sein",
  "The field should have at least 8 characters": "Das Feld sollte mindestens 8 Zeichen langsein",
  "New Password": "Neues Passwort",
  "There are no shipping methods available for this country. We are sorry, please try with different country.": "Für dieses Land sind keine Versandarten verfügbar. Es tut uns leid, bitte versuchen Sie es mit einem anderen Land oder später noch einmal.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "Beim Versuch, Versandarten abzurufen, ist ein Fehler aufgetreten. Es tut uns leid, bitte versuchen Sie es mit anderen Versanddetails oder später noch einmal.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "Beim Versuch, die Versandart auszuwählen, ist ein Fehler aufgetreten. Es tut uns leid, bitte versuchen Sie es mit einer anderen Versandart.",
  "We can't find products matching the selection.":"Wir können keine Produkte finden, die der Auswahl entsprechen.",
  "Page not found": 'Seite nicht gefunden',
  'Back to Home page': 'Zurück zur Startseite',
  'An error occurred': 'Ein Fehler ist aufgetreten',
  "AllProductsFromCategory": "Alle CategoryName",
  "Show more": "mehr anzeigen",
  "Show less": "weniger anzeigen",
  "Yes": "Ja",
  "No": "Nein",
  "Apply filters": "Filter anwenden",
  "The coupon code isn't valid. Verify the code and try again.": "Der Gutscheincode ist ungültig. Überprüfen Sie den Code und versuchen Sie es erneut.",
  "From": "Von",
  "To": "An",
  "Your customization": "Ihre Anpassung",
  "Passwords don't match":"Passwörter stimmen nicht überein",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)":"Das Passwort muss mindestens 8 Zeichen lang sein und muss mindestens enthalten: 1 Groß- oder Kleinbuchstabe, 1 Ziffer oder ein Sonderzeichen (z. B. , . _ & ? usw.)",
  "Show all products":"Alle Produkte anzeigen",
  "Select previously saved address":"Zuvor gespeicherte Adresse auswählen",
  "Enter different address":"Geben Sie eine andere Adresse ein",
  "You must confirm your account. Please check your email for the confirmation link.": "Sie müssen Ihr Konto bestätigen. Bitte überprüfen Sie Ihr E-Mail-Postfach für das Bestätigungs- E-Mail.",
  "Change Store":"Laden Wechseln",
  "Choose Currency":"Währung wählen",
  "Add a review":"Bewertung hinzufügen",
  "Add to cart":"Zum Warenkorb hinzufügen",
  "Title":"Titel",
  "Name":"Name",
  "Confirm parameters":"Attribute bestätigen",
  "Review":"Bewertungen",
  "Confirm":"Bestätigen",
  "Add review":"Bewertung hinzufügen",
  "Are you sure you would like to remove this item from the shopping cart?":"Sind Sie sicher, dass Sie den Artikel aus dem Warenkorb entfernen möchten?",
  "Your cart is empty":"Ihr Warenkorb ist leer",
  "Are you sure?":"Sind Sie sich sicher?",
  "{0} has been successfully removed from your cart":"{0} wurde erfolgreich aus Ihrem Warenkorb entfernt",
  "Amount":"Menge",
  "Thank you for your order!":"Vielen Dank für Ihre Bestellung!",
  "Your Purchase":"Ihre Bestellung",
  "Primary contacts for any questions":"Primäre Ansprechpartner für alle Fragen",
  "Your Account":"Ihr Konto",
  "What can we improve":"Was können wir verbessern?",
  "Payment date":"Zahlungsdatum",
  "The user password was changed successfully updated!":"Das Benutzerpasswort wurde erfolgreich geändert.",
  "The user account data was successfully updated!":"Die Benutzerkontodaten wurden erfolgreich aktualisiert.",
  "You submitted your review for moderation.": "Sie haben Ihre Bewertung zur Moderation eingereicht.",
  "Starting at": "Beginnt um",
  "Close": "Schließen",
  "Prev": "Zurück",
  "Next": "Weiter",
  "Add To Cart": "Zum Warenkorb hinzufügen",
  "Add To Wishlist": "Zur Wunschliste hinzufügen",
  "Category": "Kategorie",
  "New": "Neu",
  "Sale": "Sale",
  "Vervollständigen Sie Ihren Look": "Vervollständigen Sie den Look",
  "Bei anderen Kunden beliebt": "Bei anderen Kunden beliebt",
  "Size": "Größe",
  "Chart": "Chart",
  "Sichere Bezahlmöglichkeiten": "Sichere Bezahlmöglichkeiten",
  "Garantierte 14 Tage volles Rückgaberecht": "Garantierte 14 Tage volles Rückgaberecht",
  "Schnelle Lieferung innerhalb von 1 bis 4 Tagen": "Schnelle Lieferung innerhalb von 1 bis 4 Tagen",
  "Bewertungen": "Bewertungen",
  "Bewertung:": "Bewertung:",
  "Kundenbewertungen": "Kundenbewertungen",
  "Anzeigen": "Anzeigen",
  "Bewerten Sie dieses Produkt": "Bewerten Sie dieses Produkt",
  "BEWERTUNG ABSCHICKEN": "BEWERTUNG ABSCHICKEN",
  "Bin ich eine häufig gestellte Frage?": "Bin ich eine häufig gestellte Frage?",
  "Ich bin eine häufig gestellte Frage?": "Ich bin eine häufig gestellte Frage?",
  "Das sagen andere Kunden über das Produkt:": "Das sagen andere Kunden über dasProdukt:",
  "Wie gefällt Ihnen der Artikel?*": "Wie gefällt Ihnen der Artikel?*",
  "Previous look": "Vorheriger Look",
  "Next look": "Nächster Look",
  "Shop the look": "Shop the Look",
  "Your email address": "Ihre E-Mail-Adresse",
  "Language": "Sprache",
  "MIN": "MIN",
  "MAX": "MAX",
  "Im Geschäft suchen": "Im Geschäft suchen",
  "Beliebte Kategorien": "Beliebte Kategorien",
  "Subscribe to our newsletter and get 10€ on your next buy": "Abonnieren Sie unserenNewsletter und erhalten Sie 10€ auf Ihren nächsten Einkauf",
  "Durch die Eingabe meiner e-Mail Adresse bestätige ich, dass ich mit der Zusendung von Angeboten durch die Firma Palmers Textil AG einverstanden bin. Es gelten die Informationen in der Datenschutzerklärung.": "Durch die Eingabe meiner e-Mail Adresse bestätige ich, dass ich mit der Zusendung von Angeboten durch die Firma Palmers Textil AG einverstanden bin. Es gelten die Informationen in der Datenschutzerklärung.",
  "main page": "Startseite",
  "Cart Total": "Gesamtsumme",
  "To checkout": "Zur Kasse",
  "Upsell products": "Dazu passend",
  "Bügel BH": "Bügel BH",
  "Box color": "Boxfarbe",
  "Calculate route": "Route berechnen",
  "Female":"Frau",
  "Male":"Mann",
  "Company":"Firma",
  "Other":"Divers",
  "Add multiple shipping addresses":"Verwalten Sie unterschiedliche Versandadresse",
  "Faster checkout and orders history":"Schneller Bezahlprozess und eine Bestell-Historie",
  "Creating an account has many benefits":"Ein Online-Shop Konto bietet viele Vorteile",
  "I am new to Palmers":"Ich bin neu bei Palmers",
  "I have an account":"Ich habe bereits ein Konto",
  "Advertised by":"Geworben von",
  "Shoe size":"Schuhgröße",
  "Clothing size":"Konfektionsgröße",
  "Panty size":"Höschengröße",
  "Under bust size":"Unterbrustweit",
  "Bra cup":"BH-Cup",
  "Type of membership":"Art der Mitgliedschaft",
  "Mobile phone":"Handynummer",
  "Date of Birth":"Geburtsdatum",
  "Title suffix":"Titel nachgestellt",
  "Title prefix":"Title vorgestellt",
  "I have read and accepted the": "Ich habe die XXX gelesen und akzeptiert.",
  " terms and conditions. *":" terms and conditions. *",
  " privacy policy. *":" privacy policy. *",
  "No, I do not want PALMERS to process my mobile phone number to inform me about offers, competitions and events via SMS.":" Nein, ich möchte nicht, dass PALMERS meine Mobilfunknummer verarbeitet, um mich per SMS über Angebote, Gewinnspiele und Veranstaltungen zu informieren.",
  "No, I do not want to be contacted by PALMERS by phone.":" Nein, ich möchte nicht von PALMERS telefonisch kontaktiert werden.",
  "Yes, I would like to subscribe to PALMERS Promotions at my email address above to receive personalized content including coupons, offers, sweepstakes information and events.":" Ja, ich möchte PALMERS Promotions an meine oben angegebene E-Mail- Adresse abonnieren, um personalisierte Inhalte u.a. Gutscheine, Angebote, Informationen zu Gewinnspielen und Veranstaltungen zu erhalten.",
  "Yes, I would like to subscribe to the PALMERS newsletter at the e-mail address given above, so that I will always be the first to be informed about news and current offers.":" Ja, ich möchte den PALMERS Newsletter an meine oben angegebene E-Mail-Adresse abonnieren, um immer als Erstes über Neuigkeiten und aktuelle Angebote informiert zu sein.",
  "Total Tax": "Gesamtsteuer",
  "Store finder":"Filialen",
  "Support":"Kontakt",
  "Price":"Preis",
  "Join Palmers Club":"Zum Palmers Club anmelden",
  "Online Shop account":"Online-Shop Konto erstellen",
  "Create a new account":"Neues Kundenkonto anlegen",
  "Confirm password":"Passwort bestätigen",
  "Create an account":"Konto erstellen",
  "Password confirmation does not match":"Passwörter stimmen nicht überein.",
  "A customer with the same email address already exists in an associated website.":"E-Mail-Adresse bereits vergeben.",
  "Cart":"Warenkorb",
  "Account":"Konto",
  "Add new delivery address":"Neue Adresse hinzufügen",
  "Welcome back, ":"Willkommen zurück, ",
  "View all":"Alle anzeigen",
  "Recent orders":"Letzte Bestellungen",
  "My Addresses":"Meine Adressen",
  "Addresses":"Adressbuch",
  "Add a delivery address":"Neue Adresse hinzufügen",
  "Default Billing":"STANDARD­RECHNUNGS­ADRESSE",
  "Default Shipping":"STANDARD­VERSAND­ADRESSE",
  "Default":"Standard",
  "Add New":"Neue Adresse hinzufügen",
  "Club Status - ":"Club-Status - ",
  "Change password":"Passwort ändern",
  "fixed discount for Cart":"Fester Rabatt für den Warenkorb",
  "Loyalty and vouchers":"Treue und Gutscheine",
  "Your wishlist is empty":"Ihre Wunschliste ist leer",
  "Back to shopping":"Zurück zum Einkaufen",
  "Add all items to Shopping Cart":"Alle Artikel in den Einkaufswagen legen",
  "View Shopping Cart":"Warenkorb ansehen und bearbeiten",
  "Apply Coupon code":"Gutscheincode anwenden",
  "Apply":"Anwenden",
  "Send":"Schicken",
  "The requested qty is not available":"Derzeit haben wir keinen Bestand mehr.",
  "Add new address":"Neue Adresse hinzufügen",
  "Enter your email address and we will send you an email to change your password.":"Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen eine E-Mail, um Ihr Passwort zu ändern.",
  "Delivery":"Versand",
  "Account verified. You can try to login": "Konto verifiziert. Sie können versuchen, sich anzumelden",
  "Registration successful":"Registrierung erfolgreich",
  "Please check your inbox to confirm email address":"Sie erhalten in Kürze ein E-Mail zugesendet, um Ihre E-Mail-Adresse zu bestätigen.",
  "Thanks":"Danke",
  "Color":"Farbe",
  "Reviews":"Bewertungen",
  "No products were found for your request":"Für Ihre Anfrage wurden keine Produkte gefunden",
  "Opening hours":"Öffnungszeiten",
  "More details":"Mehr Details",
  "Accept and load content":"Inhalte akzeptieren und laden",
  "Attach files":"Dateien anhängen",
  "Attachments":"Anhänge",
  "Contact support":"Kundenservice",
  "Your order id {order_id} was create with error, we will contact you by {email} or":"Ihre Bestellung, mit der Bestellnummer {order_id}, konnte nicht erfolgreich gespeichert werden. Sollte erneut ein Fehler auftreten, kontaktieren Sie bitte unseren.",
  "Your selected payment method was declined.":"Ihre ausgewählte Zahlungsmethode wurde abgelehnt.",
  "There was a problem with your payment":"Es gab ein Problem mit Ihrer Zahlung",
  "Email with all details regarding your order is sent to: ": "Eine E-Mail, mit allen Details zu Ihrer Bestellung, wird an folgende Adresse gesendet: ",
  "Your order number is: ":"Ihre Bestellnummer:",
  "Secure connection":"Sichere Verbindung",
  "Thanks for shopping with us!":"Vielen Dank für Ihren Einkauf bei uns!",
  "Change":"Ändern",
  "Apply changes":"Änderungen speichern",
  "Shipping methods":"Versandmethoden",
  "Billing address":"Rechnungsadresse",
  "Street extra":"Adresszusatz",
  "Birth date":"Geburtsdatum",
  "Or continue with":"Oder fortfahren mit",
  "Continue as guest":"Als Gast fortfahren",
  "Same address for shipping and billing":"Gleiche Adresse für Versand und Rechnung",
  "Back":"Zurück",
  "back":"zurück",
  "Phone":"Telefon",
  "Order #":"Bestellung # ",
  "Order":"Bestellung",
  "Ordered Items":"Bestellte Artikel",
  "Date":"Datum",
  "Bills":"Rechnungen",
  "Order shipments":"Sendungen bestellen",
  "Refunds":"Rückerstattungen",
  "Subtotal:":"Zwischensumme:",
  "You wishlist is empty":"Sie haben keine Artikel auf Ihrem Wunschzettel.",
  "Overview":"Übersicht",
  "Log in":"Anmelden",
  "items":"Anzahl",
  "Log in to your account to be able to view your wish list at any time on any device and to add the selected items to your shopping cart. You can quickly and easily request your favorite products in our stores by showing your wish list.":"Loggen Sie sich in Ihr Konto ein, um Ihre Wunschliste jederzeit auf jedem Gerät ein zu sehen und die ausgewählten Artikel in den Warenkorb legen zu können. Mit dem Warenkorb finden Sie schnell und einfach Ihre Lieblingsprodukte.",
  "Tracking number":"Tracking-Nummer",
  "Tracking number: ":"Tracking-Nummer: ",
  "Sent to":"Versendet an",
  "Order total":"Bestellsumme",
  "First name":"Vorname",
  "Last name":"Nachname",
  "Order status":"Bestellstatus",
  "Street & Apartment":"Straße und Hausnummer",
  "Track your orders, and many more...":"Verfolgen Sie Ihre Bestellungen und vieles mehr...",
  "This product is out of stock": "Diese Ware ist nicht mehr auf Lager",
  "Address name": "Name der Adresse",
  "Newsletter": "Newsletter",
  "Address": "Adresse",
  "Palmers Club": "Palmers Club",
  "Kontakt": "Kontakt",
  "Impressum": "Impressum",
  "Rechtshinweise": "Rechtshinweise",
  "Cookies": "Cookies",
  "Datenschutz": "Datenschutz",
  "Home page": "Home page",
  "KUNDENSERVICE": "KUNDENSERVICE",
  "Palmers Münzen": "Palmers Münzen",
  "FAQ": "FAQ",
  "QTY:": "Anzahl:",
  "Größenberater": "Größenberater",
  "Presse": "Presse",
  "Sort": "Sortieren",
  "Error": "Fehler",
  "Error: The requested qty is not available": "Fehler: Derzeit haben wir keinen Bestand mehr",
  "Error: Auth is not success": "Fehler: Authentifizierung ist nicht erfolgreich",
  "Error: Payment method error": "Fehler: Fehler bei der Zahlungsmethode",
  "Invalid characters used": "Ungültige Zeichen (erlaubt: A-ß)",
  "Not shipped": "Nicht ausgeliefert",
  "Zip": "Zip",
  "km": "km",
  "m": "m",
  "Payment method": "ZAHLUNGSMETHODE",
  "Monday": "Montag",
  "Tuesday": "Dienstag",
  "Wednesday": "Mittwoch",
  "Thursday": "Donnerstag",
  "Friday": "Freitag",
  "Saturday": "Samstag",
  "Sunday": "Sonntag",
  "Change address": "Adresse ändern",
  "Shipping and handling": "Versandkosten",
  "Invoice": "Rechnung",
  "Print Invoice": "Rechnung drucken",
  "Tax": "Steuer",
  "Credit / Debit Card": "Kreditkarte",
  "Sofort Banking": "Sofortüberweisung",
  "Shipped": "Versendet",
  "Refunded": "Erstattet",
  "Print Delivery": "Lieferschein drucken",
  "Shipped quantity": "Gelieferte Menge",
  "The field may only contain alphabetic characters": "Das Feld darf nur alphabetische Zeichen enthalten",
  "The field may only contain alphabetic characters or numbers": "Das Feld darf nur alphabetische Zeichen oder Zahlen enthalten",
  "The field must be numeric": "Das Feld muss numerisch sein",
  "The field field may only contain numeric characters": "Das Feld darf nur numerische Zeichen enthalten",
  "The field field may only contain decimal characters": "Das Feld darf nur Dezimalzeichen enthalten",
  "The field does not match pattern: {regex}": "Das Feld stimmt nicht mit dem Muster überein: {regex}"
};
