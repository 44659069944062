import {computed, reactive, useContext} from "@nuxtjs/composition-api";

const menuData = reactive({
  activeCategoryIndex: <number>-1,
  activeCategory: {}
});

const useMenu = () => {
  //@ts-ignore
  const {$pinia} = useContext();
  const categories = computed(() => $pinia.state.value.category?.rawCategories?.items[0]);

  const activeCategory = computed(() => menuData.activeCategory);
  const activeCategoryIndex = computed(() => menuData.activeCategoryIndex);

  const getPossibleLinks = (category) => {
    const possibleLinks = [];

    if (category?.url_key) {
      possibleLinks.push(category.url_key);
      possibleLinks.push(`${category.url_key}.html`);
      if(category.url_key === 'home') {
        possibleLinks.push('/');
      }
    }

    if (category?.url_path) {
      possibleLinks.push(category.url_path);
      if(category.url_path === 'home') {
        possibleLinks.push('/');
      }
    }

    if (category?.url_suffix && category?.url_path) {
      possibleLinks.push(`${category.url_path}${category.url_suffix}`);
      if(`${category.url_path}${category.url_suffix}` === `home${category.url_suffix}`) {
        possibleLinks.push('/');
      }
    }


    return possibleLinks;
  }

  const findActiveCategory = (uid = false, link = false) => {
    const filteredCategories = categories.value?.children?.filter((categoryMenu) => categoryMenu.include_in_menu) || [];

    if(uid) {
      return filteredCategories.findIndex((category) => {
        let child;

        if(category?.children?.length > 0) {
          child = category.children.find((categoryChild) => categoryChild.uid === uid)
        }

        return category.uid === uid || child
      })
    }

    if(link) {
      return filteredCategories.findIndex((category) => {
        let possibleLinks = getPossibleLinks(category);
        if(category?.children?.length > 0) {
          category.children.map((categoryChild) => {
            possibleLinks = [
              ...possibleLinks,
              ...getPossibleLinks(categoryChild)
            ]
          })
        }

        return possibleLinks.includes(link);
      })
    }

    return -1;
  }

  const setActiveCategory = (category) => {
    if(category) {
      const {uid, link} = category;
      menuData.activeCategoryIndex = findActiveCategory(uid, link);
      return;
    }
    menuData.activeCategoryIndex = -1;
  }


  return {
    activeCategory,
    activeCategoryIndex,
    setActiveCategory
  }
};

export default useMenu;
