



import {
  defineComponent
} from '@nuxtjs/composition-api';
import {useGtm} from "../../composables/Palmers";
import {useConfigStore} from "~/stores/config";

export default defineComponent({
  name: 'GTM',
  setup() {
    const {init} = useGtm();

    return {
      init
    }
  },
  mounted() {
    this.init();
  }
});

