import gql from 'graphql-tag';

export default gql`
  query productDetails($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 1, $updated: String) {
    products(search: $search, filter: $filter, pageSize: $pageSize, updated: $updated, mode: "product") {
      items {
        id
        categories {
          uid
          name
          url_suffix
          url_path
          breadcrumbs {
            category_uid
            category_name
            category_url_key
            category_url_path
          }
          __typename
        }
        ... on ConfigurableProduct {
          configurable_options {
            attribute_code
            attribute_uid
            label
            position
            uid
            use_default
            values {
              value_index
              label
              swatch_data {
                value
                __typename
              }
              uid
              __typename
            }
            __typename
          }
          variants {
            product {
              id
              diamond_price
              price_range {
                maximum_price {
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
                minimum_price {
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
              }
              sku
              color
              pa_size
              image {
                disabled
                label
                position
                url
                __typename
              }
              muenze_boxfarbe
              muenze_schleife
              uid
              media_gallery {
                disabled
                label
                position
                url
                ... on ProductVideo {
                  video_content {
                    media_type
                    video_provider
                    video_url
                    video_title
                    video_description
                    video_metadata
                  }
                }
                __typename
              }
              stock_status
              only_x_left_in_stock
            }
          }
        }
        ... on SimpleProduct {
          diamond_price
          image {
            disabled
            label
            position
            url
            __typename
          }
        }
        media_gallery {
          disabled
          label
          position
          url
          ... on ProductVideo {
            video_content {
              media_type
              video_provider
              video_url
              video_title
              video_description
              video_metadata
            }
          }
          __typename
        }
        description {
          html
          __typename
        }
        meta_description
        meta_keyword
        meta_title
        name
        pa_series
        pa_displayname
        pa_material_composition
        pa_gender_overdose {
          label
        }
        pa_brand_overdose {
          label
        }
        pa_specials_overdose {
          label
        }
        pa_productcategory_overdose {
          label
        }
        pa_class {
          value
          label
          media_url
        }
        pa_carelabel_bleach {
          value
          label
          media_url
        }
        pa_carelabel_iron {
          value
          label
          media_url
        }
        pa_carelabel_wash {
          value
          label
          media_url
        }
        pa_care_label {
          value
          label
          media_url
        }
        pa_carelabel_chem {
          value
          label
          media_url
        }
        pa_carelabel_tumble {
          value
          label
          media_url
        }
        created_at
        only_x_left_in_stock
        options_container
        short_description {
          html
          __typename
        }
        sku
        small_image {
          disabled
          label
          position
          url
          __typename
        }
        special_to_date
        special_from_date
        stock_status
        thumbnail {
          disabled
          label
          position
          url
          __typename
        }
        uid
        url_key
        url_rewrites {
          url
          __typename
        }
        __typename
      }
    }
  }
`;
