import {computed, reactive, useContext} from '@nuxtjs/composition-api';
import {Cart, Order} from "~/modules/GraphQL/types";
import {computopCwAutorize, saferpayCwAutorize} from "~/customQueries/queries";
import Form from '~/pages/_Palmers/Checkout/Form';
import {mergeItem} from "~/helpers/asyncLocalStorage";

const paymentData = reactive({
  order: <Order>{},
  cart: <Cart>{},
  klarna: {},
  orderError: false,
  klarnaRefresh: false
});

const usePayments = () => {
  const klarna = computed(() => paymentData.klarna);
  const orderError = computed(() => paymentData.orderError);
  const klarnaRefresh = computed(() => paymentData.klarnaRefresh);

  const {$vsf} = useContext();

  const setOrderError = (val) => {
    paymentData.orderError = val;
  }

  const setKlarnaRefresh = (val) => {
    paymentData.klarnaRefresh = val;
  }

  const setOrder = (order) => {
    paymentData.order = order?.order;
  }

  const setKlarna = (klarna) => {
    paymentData.klarna = klarna;
  }

  const setCart = (cart) => {
    paymentData.cart = cart;
  }

  const process = async () => {
    if(!paymentData.order) return false;
    const {selected_payment_method} = paymentData.cart

    if(selected_payment_method?.code.indexOf("computopcw_") > -1 || selected_payment_method?.code.indexOf("saferpaycw_") > -1) {
      if(!paymentData.order.order_number) return false;

      const queryCw = selected_payment_method?.code.indexOf("computopcw_") > -1 ? computopCwAutorize : saferpayCwAutorize;

      const {data} = (await $vsf.$magento.api.customQuery({
        query: queryCw,
        queryVariables: {
          order_increment_id: paymentData.order.order_number
        }
      }))

      const {formActionUrl, hiddenFormFields, redirectionUrl} = data?.['computopCwAutorize'] || data?.['saferpayCwAutorize'];

      if(formActionUrl && hiddenFormFields.length) {
        const fields = [];

        for (let f = 0; f < hiddenFormFields.length; f++) {
          fields[hiddenFormFields[f].key] = hiddenFormFields[f].value
        }

        const processFormAction = formActionUrl.replace('paynow.aspx', 'payssl.aspx')

        const form = new Form(processFormAction, fields)

        window.history.pushState('', '', `?backToPayment=${paymentData.cart?.id}`);

        form.submit();

        await mergeItem('checkout', {'payment': {order_id: paymentData.order.order_number, email: paymentData.cart.email}, 'cart': paymentData.cart});

        return 'wait';
      }

      if(redirectionUrl) {
        await mergeItem('checkout', {'payment': {order_id: paymentData.order.order_number, email: paymentData.cart.email}, 'cart': paymentData.cart});

        window.history.pushState('', '', `?backToPayment=${paymentData.cart?.id}`);

        window.location.href = redirectionUrl;

        return 'wait';
      }


      return  false;
    }

    return true;
  }


  const getKlarnaOrderData = (cart) => {
    const shippingAddress = cart.shipping_addresses[0];
    const billingAddress = cart.billing_address;

    const shippingAddressKlarna = {
      email: cart.email,
      title: shippingAddress?.prefix ? shippingAddress.prefix : null,
      given_name: shippingAddress.firstname,
      family_name: shippingAddress.lastname,
      street_address: shippingAddress.street[0],
      city: shippingAddress.city,
      postal_code: shippingAddress.postcode,
      country: shippingAddress.country.code,
      region: shippingAddress.region.code,
      phone: shippingAddress.telephone,
      street_address2: shippingAddress.street[1],
      organization_name: null
    }

    const billingAddressKlarna = {
      email: cart.email,
      title: billingAddress?.prefix ? billingAddress.prefix : null,
      given_name: billingAddress.firstname,
      family_name: billingAddress.lastname,
      street_address: billingAddress.street[0],
      city: billingAddress.city,
      postal_code: billingAddress.postcode,
      country: billingAddress.country.code,
      region: billingAddress.region.code,
      phone: billingAddress.telephone,
      street_address2: billingAddress.street[1],
      organization_name: null
    }


    return {
      billing_address: billingAddressKlarna,
      shipping_address: shippingAddressKlarna,
      customer: {
        type: 'person'
      }
    };
  }

  return {
    setOrder,
    setCart,
    process,
    setKlarna,
    klarna,
    getKlarnaOrderData,
    orderError,
    setOrderError,
    klarnaRefresh,
    setKlarnaRefresh
  }
};

export default usePayments;
