import useUser from "~/modules/customer/composables/useUser";
import useCart from "~/modules/checkout/composables/useCart";
import useWishlist from "~/modules/wishlist/composables/useWishlist";
import {watch} from "@nuxtjs/composition-api";
import {useWishlistStore} from "~/modules/wishlist/store/wishlistStore";

const useLoggedWatcher = () => {
  const wishlistStore = useWishlistStore();
  const {isAuthenticated, load: loadUser} = useUser();
  const {load: loadCart} = useCart();
  const {load: loadWishlist} = useWishlist();

  watch(isAuthenticated, async (newVal, oldVal) => {
    if(newVal === false && oldVal === true) {
      await Promise.all([
        loadCart(),
        loadWishlist(),
        loadUser()
      ])

      wishlistStore.$patch((state) => {
        state.wishlist = {}
      });
    }
  });
};

export default useLoggedWatcher;
