import {computed, reactive, useContext, useRoute} from "@nuxtjs/composition-api";
import cartGetters from "~/modules/checkout/getters/cartGetters";
import {usePageStore} from "~/stores/page";
import productGetters from "~/modules/catalog/product/getters/productGetters";
import {useConfigStore} from "~/stores/config";
import wishlistGetters from "~/modules/wishlist/getters/wishlistGetters";
import {useCartStore} from "~/modules/checkout/stores/cart";
import palmersProductGetters from "~/modules/palmers/product/getters/palmersProductGetters";
import {getItem, mergeItem} from "~/helpers/asyncLocalStorage";

const gtmData = reactive({
  loadedProducts: [],
  loadedProduct: {},
  loadedCategory: {}
});

const useGtm = () => {
  const context = useContext();

  const configStore = useConfigStore();
  const cartStore = useCartStore();
  const {storeConfig} = configStore;

  const route = useRoute();
  const cart = computed(() => cartStore.cart);

  const pageStore = usePageStore();
  const {routeData} = pageStore;

  //@ts-ignore
  const {$gtm, $vsf, $pinia} = context;
  const customer = computed(() => $pinia.state.value.customer);

  const setLoadedProducts = (products) => {
    gtmData.loadedProducts = products;
  }

  const setLoadedProduct = (product) => {
    gtmData.loadedProduct = product;
  }

  const setLoadedCategory = (category) => {
    gtmData.loadedCategory = category;
  }

  const affiliate = async (newRoute) => {
    if(!Boolean(window?.['OnetrustActiveGroups']?.replace(',C0001,', ''))) return;

    try {
      const mapScriptsAndNonScriptElements = (parent) => {
        const containerDiv = document.createElement('div');
        containerDiv.id = `${parent.id}-scripts`;

        const scriptTags = parent.querySelectorAll('script');
        scriptTags.forEach((script) => {
          const clonedScript = document.createElement('script');
          if (script.src) {
            clonedScript.src = script.src;
          } else {
            clonedScript.textContent = script.textContent;
          }
          containerDiv.appendChild(clonedScript);
          script.parentNode.removeChild(script);
        });

        if(parent.innerHTML !== containerDiv.innerHTML) {
          parent.appendChild(containerDiv);
        }

        return parent;
      }

      let type = 'all';

      if (routeData?.type) {
        switch (routeData.type) {
          case 'PRODUCT': {
            type = 'product_page';
            break;
          }
          case 'SUB_CATEGORY': {
            type = 'category_page';
            break;
          }
        }
      }

      if (newRoute.name.indexOf('cart') > -1) {
        type = 'cart_page';
      }

      if (newRoute.name.indexOf('shipping') > -1
        || newRoute.name.indexOf('shipping-methods') > -1
        || newRoute.name.indexOf('payment') > -1) {
        type = 'one_page_chackout';
      }

      if(newRoute.name.indexOf('thank-you') > -1) {
        type = 'checkout_success';
      }

      if(newRoute.name.indexOf('home') > -1) {
        type = 'home_page';
      }

      if(newRoute.name.indexOf('search') > -1) {
        type = 'catalogsearch_result_page';
      }

      const affiliate = await context.$vsf.$magento.api.affiliate(type)

      if(
        !affiliate?.data?.['affiliate']?.['scripts']?.['head']
        && !affiliate?.data?.['affiliate']?.['scripts']?.['bodybegin']
        && !affiliate?.data?.['affiliate']?.['scripts']?.['bodyend']
      ) return;

      const {head, bodybegin, bodyend} = affiliate?.data?.['affiliate']?.['scripts'];

      document.getElementById('affiliate-head')?.remove();
      document.getElementById('affiliate-bodybegin')?.remove();
      document.getElementById('affiliate-bodyend')?.remove();
      document.getElementById('AW_ALT')?.remove();

      const iframes = document.querySelectorAll('iframe[srcdoc^="<body><script>"]');
      const scripts = document.querySelectorAll('script[id^="_aw"]');
      const imgs = document.querySelectorAll('img[src^="https://lantern.roeye.com/"]');

      if(iframes.length) {
        iframes.forEach((iframe) => {
          iframe.parentNode.removeChild(iframe);
        });
      }
      if(scripts.length) {
        scripts.forEach((script) => {
          script.parentNode.removeChild(script);
        });
      }
      if(imgs.length) {
        imgs.forEach((img) => {
          img.parentNode.removeChild(img);
        });
      }

      if (head) {
        const headElement = document.createElement('div');
        headElement.innerHTML = head;
        headElement.id = 'affiliate-head';
        document.getElementsByTagName("head")[0].prepend(mapScriptsAndNonScriptElements(headElement));
      }

      if (bodybegin) {
        const bodyBeginElement = document.createElement('div');
        bodyBeginElement.innerHTML = bodybegin;
        bodyBeginElement.id = 'affiliate-bodybegin';
        document.getElementsByTagName("body")[0].prepend(mapScriptsAndNonScriptElements(bodyBeginElement));
      }

      if (bodyend) {
        const bodyEndElement = document.createElement('div');
        bodyEndElement.innerHTML = bodyend;
        bodyEndElement.id = 'affiliate-bodyend';
        document.getElementsByTagName("body")[0].append(mapScriptsAndNonScriptElements(bodyEndElement));
      }
    } catch (e) {
      console.error(e);
    }
  }

  const view = (newRoute = route.value) => {
    if (!process.client) return;

    affiliate(newRoute);

    if (newRoute.name.indexOf('page') > -1) {
      if (routeData?.type) {
        switch (routeData.type) {
          case 'PRODUCT': {
            const product = getItemsList([gtmData.loadedProduct]);
            $gtm.push({
              event: 'impression',
              ...getPageInfo(),
              product_sku: product[0]?.sku,
              ecommerce: {
                currencyCode: $vsf.$magento.config.state.getCurrency(),
                detail: {
                  products: product
                },
                impressions: getItemsList([...(gtmData.loadedProduct?.['related_products'] || []), ...(gtmData.loadedProduct?.['upsell_products'] || []), ...(gtmData.loadedProduct?.['bestSellers'] || [])])
              },
              eventCategory: 'Ecommerce',
              eventAction: 'Impression',
              eventLabel: `product ${gtmData.loadedProduct?.['name']}`,
              ...getCustomerInfo(),
            });
            break;
          }
          case 'SUB_CATEGORY': {
            $gtm.push({
              event: 'impression',
              ...getPageInfo(),
              ecommerce: {
                currencyCode: $vsf.$magento.config.state.getCurrency(),
                impressions: getItemsList(gtmData.loadedProducts)
              },
              eventCategory: 'Ecommerce',
              eventAction: 'Impression',
              eventLabel: `category ${gtmData.loadedCategory?.['name']}`,
              ...getCustomerInfo(),
            });
            break;
          }
        }
      }
    }
  }

  const getPageInfo = () => {
    const pageTyp = {
      CMS_PAGE: 'page',
      CATEGORY: 'category',
      SUB_CATEGORY: 'category',
      PRODUCT: 'product'
    }

    let pageType;

    if (isPageToFetch(route.value)
      && pageTyp?.[routeData?.type]
    ) {
      pageType = pageTyp[routeData?.type];
    } else {
      pageType = route.value.name.split('__')[0];
    }

    return {
      pageName: document.title,
      pageType: pageType || pageTyp['CMS_PAGE']
    }
  }

  const getCustomerInfo = () => {
    const data = {
      customerGroup: !customer.value?.isLoggedIn ? 'NOT LOGGED IN' : 'LOGGED IN',
    }

    if (customer.value?.isLoggedIn) {
      data['customerId'] = customer.value?.user?.id || customer.value?.user?.email;
    }

    return data;
  }

  const getCategoriesPath = (categories) => {
    let path = '';

    if (!categories) return path;

    for (let c = 0; c < categories.length; c++) {
      if (!categories[c]?.name) continue;
      path += categories[c]?.name + '/';
    }

    return path.slice(0, -1);
  }

  const getDimension = (product) => {
    const dimension = {};

    if (storeConfig) {
      const {
        //@ts-ignore
        weltpixel_googletagmanager_general_track_stockstatus,
        //@ts-ignore
        weltpixel_googletagmanager_general_track_reviewscount,
        //@ts-ignore
        weltpixel_googletagmanager_general_track_reviewsscore
      } = storeConfig;

      if (weltpixel_googletagmanager_general_track_stockstatus === '1') {
        dimension['stock_status'] = product?.stock_status;
      }

      if (weltpixel_googletagmanager_general_track_reviewscount === '1') {
        dimension['review_count'] = product?.review_count;
      }

      if (weltpixel_googletagmanager_general_track_reviewsscore === '1') {
        dimension['rating_summary'] = product?.rating_summary;
      }
    }

    return dimension;
  }

  const getProductSkus = (items) => {
    const skus = [];

    for (let p = 0; p < items.length; p++) {
      if(items?.[p]?.sku) {
        skus.push(items[p].sku);
      }
    }

    return skus;
  }

  const checkout = async (newRoute = route.value) => {
    const currency = $vsf.$magento.config.state.getCurrency();

    await affiliate(newRoute);

    const orderData = await getItem('checkout');

    let data = {
      ecommerce: {
        currencyCode: currency
      },
      ...getCustomerInfo(),
      ...getPageInfo()
    } as any;

    const realCart = orderData?.['cart'] || cart.value;
    const items = getItems(realCart?.items || realCart?.items || [], cartGetters);

    if (items.length && newRoute.fullPath.indexOf('checkout') > -1) {
      data = {
        ...data,
        ecommerce: {
          ...data.ecommerce,
          checkout: {
            products: items
          }
        }
      }
    }

    if (newRoute.name.indexOf('shipping') > -1) {
      data = {
        ...data,
        'event': 'checkoutOption',
        'eventLabel': 'Checkout Steps',
        ecommerce: {
          ...data.ecommerce,
          'checkout_option': {
            'actionField': {
              'step': 2,
              'option': 'not selected'
            }
          }
        }
      }
      if (cart.value?.shipping_addresses?.[0]?.selected_shipping_method?.method_title) {
        data.ecommerce.checkout_option.actionField.option = cart.value?.shipping_addresses?.[0]?.selected_shipping_method?.method_title
      }
    }

    if (newRoute.name.indexOf('shipping-methods') > -1) {
      data = {
        ...data,
        'event': 'checkoutOption',
        'eventLabel': 'Checkout Steps',
        ecommerce: {
          ...data.ecommerce,
          'checkout_option': {
            'actionField': {
              'step': 3,
              'option': 'not selected'
            }
          }
        }
      }

      if (cart.value?.shipping_addresses?.[0]?.selected_shipping_method?.method_title) {
        data.ecommerce.checkout_option.actionField.option = cart.value?.shipping_addresses?.[0]?.selected_shipping_method?.method_title
      }
    }
    if (newRoute.name.indexOf('payment') > -1) {
      data = {
        ...data,
        'event': 'checkoutOption',
        'eventLabel': 'Checkout Steps',
        ecommerce: {
          ...data.ecommerce,
          'checkout_option': {
            'actionField': {
              'step': 4,
              'option': 'not selected'
            }
          }
        }
      }

      if (cart.value?.selected_payment_method?.code) {
        data.ecommerce.checkout_option.actionField.option = cart.value?.selected_payment_method?.code
      }
    }

    if (newRoute.name.indexOf('cart') > -1) {
      data = {
        ...data,
        'event': 'checkout_cart',
        ecommerce: {
          ...data.ecommerce
        },
        grand_total: cartGetters.getTotals(cart.value)?.total
      }
    }

    if (newRoute.name.indexOf('thank-you') > -1 && data?.ecommerce?.checkout?.products) {
      data = {
        ...data,
        'event': 'checkout_success',
        product_skus: getProductSkus(data.ecommerce.checkout.products),
        ecommerce: {
          ...data.ecommerce,
          purchase: {
            products: data.ecommerce.checkout.products,
            'actionField': {
              'id': orderData?.['payment']?.order_id,
              'revenue': cartGetters.getTotals(realCart)?.total,
              'coupon': cartGetters.getAppliedCoupon(realCart)?.code,
              'tax': realCart?.prices?.applied_taxes?.[0]?.amount?.value,
              'shipping': cartGetters.getShippingPrice(realCart)
            }
          }
        }
      }
      delete data.ecommerce.checkout;

      if (orderData?.['cart']) {
        mergeItem('checkout', {'cart': null})
      }
    }

    if (data?.ecommerce?.checkout?.products || data?.ecommerce?.['purchase']?.products) {
      $gtm.push(data)
    }
  }

  const getItemsList = (items) => {
    const eItems = [];

    for (let p = 0; p < items.length; p++) {
      const product = items[p];

      eItems.push(getProductData(product, productGetters));
    }

    return eItems;
  }

  const getProductData = (product, getter, item = false) => {
    const catPath = getCategoriesPath(product?.categories);

    const data = {
      'name': product.name,
      'id': product.sku,
      'sku': product.sku,
      'price': productGetters.getPrice(product)?.regular,
      'category': catPath,
      'list': catPath,
      ...getDimension(product)
    };

    if (item) {
      data['quantity'] = getter.getItemQty(item) || 1;

      let childName;
      let childSku = item?.['configured_variant']?.sku || item?.[0]?.sku;

      if (item?.['productConfiguration']) {
        const childProduct = palmersProductGetters.getVariantByConfiguration(product, item['productConfiguration']);
        childSku = childProduct?.sku;
        childName = childProduct?.name;
      }

      data['child_id'] = childSku;
      data['child_name'] = childSku && !childName ? product.variants.find((child) => child?.product?.sku === childSku)?.name : childName ? childName : ''
    }

    if (product?.['productConfiguration']) {
      const childProduct = palmersProductGetters.getVariantByConfiguration(product, product['productConfiguration']);
      const childSku = childProduct?.sku;
      const childName = childProduct?.name;
      data['child_id'] = childSku;
      data['child_name'] = childSku && !childName ? product.variants.find((child) => child?.product?.sku === childSku)?.name : childName ? childName : ''
    }

    return data;
  }

  const getItems = (items, getter) => {
    const eItems = [];

    for (let p = 0; p < items.length; p++) {
      const item = items[p];
      const product = item.product;

      eItems.push(getProductData(product, getter, item));
    }

    return eItems;
  }


  const isPageToFetch = (route) => {
    return (
      (route.name.indexOf('home') > -1)
      || (route.name.indexOf('page') > -1)
    )
  }

  const init = () => {
    if (
      storeConfig
      && storeConfig?.['weltpixel_googletagmanager_general_enable'] === '1'
      && storeConfig?.['weltpixel_googletagmanager_general_google_tag_id']
    ) {

      $gtm.init(storeConfig['weltpixel_googletagmanager_general_google_tag_id']);
    }
  }

  const getEccommerceData = (products, getter, event, action) => {
    const eItems = getItems(products, getter);
    const currency = $vsf.$magento.config.state.getCurrency();

    if (!eItems.length) return false;

    return {
      'event': event,
      'ecommerce': {
        'currencyCode': currency,
        'impressions': getItemsList(gtmData.loadedProducts),
        [action]: {
          products: [eItems[0]]
        },
      },
      'eventLabel': eItems[0]?.name,
      'eventCategory': 'Ecommerce',
      'eventAction': 'Impression',
      ...getCustomerInfo(),
      ...getPageInfo()
    }
  }

  const trackAddToCart = (products) => {
    const ecommerce = getEccommerceData(products, cartGetters, 'addToCart', 'add');

    if (ecommerce) {
      $gtm.push(ecommerce);
    }
  }

  const trackRemoveFromCart = (products) => {
    const ecommerce = getEccommerceData(products, cartGetters, 'removeFromCart', 'remove');

    if (ecommerce) {
      $gtm.push(ecommerce);
    }
  }

  const trackAddToWishlist = (products) => {
    const ecommerce = getEccommerceData(products, wishlistGetters, 'addToWishlist', 'add');

    if (ecommerce) {
      $gtm.push(ecommerce);
    }
  }

  const trackRemoveFromWishlist = (products) => {
    const ecommerce = getEccommerceData(products, wishlistGetters, 'removeFromWishlist', 'remove');

    if (ecommerce) {
      $gtm.push(ecommerce);
    }
  }

  return {
    init,
    view,
    checkout,
    setLoadedProducts,
    trackAddToCart,
    trackRemoveFromCart,
    trackAddToWishlist,
    trackRemoveFromWishlist,
    setLoadedProduct,
    setLoadedCategory
  }
};

export default useGtm;
