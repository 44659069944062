import gql from 'graphql-tag';

export default gql`
  query customer {
    customer {
      id
      sf_agb
      sf_datenschutz
      sf_is_online_customer
      sf_registration_type
      sf_company
      sf_telephone
      gender
      foot_wear
      wear
      bra
      phone
      company
      default_billing
      default_shipping
      email
      firstname
      club_fee_outstanding
      treuebonus_differenz
      group_name
      sconto
      is_subscribed
      lastname
      prefix
      title_prefix
      sf_requestedcardtype
      title_suffix
      addresses {
        address_gender
        address_dob
        address_name
        city
        company
        country_code
        default_billing
        default_shipping
        extension_attributes {
          attribute_code
          value
        }
        firstname
        id
        lastname
        postcode
        prefix
        region {
          region_code
          region_id
          region
        }
        street
        suffix
        telephone
        vat_id
      }
    }
  },
`;
