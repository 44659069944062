<template>
  <transition-group
    tag="div"
    class="notifications"
    name="slide-fade"
  >
    <SfNotification
      v-for="notification in mergedNotifications"
      :key="notification.id"
      :message="notification.message"
      :action="notification.action && notification.action.text"
      :type="notification.type"
      visible
      @click:close="notification.dismiss"
      @click:action="notification.action && notification.action.onClick()"
    >
      <template
        #icon
      />
    </SfNotification>
  </transition-group>
</template>

<script>
import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { SfIcon } from '@storefront-ui/vue';
import { useUiNotification } from '~/composables';
import SfNotification from '~/components/Palmers/BaseComponents/Notification/SfNotification.vue';

export default defineComponent({
  name: 'Notification',
  components: {
    SfNotification,
    SfIcon,
  },
  setup() {
    const { notifications } = useUiNotification();

    const mergedNotifications = ref([]);

    const mergeNotifications = () => {
      const merged = {};
      notifications.value.forEach(notification => {
        const { id, type, message, action, dismiss } = notification;
        if (!merged[type]) {
          merged[type] = {
            id,
            type,
            message: [],
            action: action && { ...action },
            dismiss,
          };
        }
        merged[type].message.push(message);
      });

      return Object.keys(merged).map(type => {
        const mergedNotification = merged[type];
        mergedNotification.message = mergedNotification.message.join('.\n\n');
        return mergedNotification;
      });
    };

    watch(notifications, () => {
      mergedNotifications.value = mergeNotifications();
    });

    return {
      mergedNotifications
    };
  }
});
</script>
<style lang="scss">
  @import "assets/css/source/components/page-notification";
</style>
