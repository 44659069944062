import {QueryParams} from "~/composables";
import {useAttributesStore} from "~/stores/attributes";

export function ignoreAnalyticsParams(routerQuery: QueryParams, ignore = [], productAttributes = null) {
  const ignoreMe = ['sort', 'page', 'itemsPerPage', 'category_uid', 'search', 'term', ...ignore];

  const {attributesData} = useAttributesStore();

  const routerQueryKeys = Object.keys(routerQuery || {});

  if(productAttributes) {
    for(let a = 0; a < productAttributes.length; a++) {
      ignoreMe.push(productAttributes[a]?.attribute_uid)
    }
  }

  for(let q = 0; q < routerQueryKeys.length; q++) {
    if(ignoreMe.includes(routerQueryKeys[q])) continue;
    if(!attributesData?.['attributes']?.includes(routerQueryKeys[q])) {
      delete routerQuery[routerQueryKeys[q]];
    }
  }

  return routerQuery;
}
