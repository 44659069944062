import gql from 'graphql-tag';

export default gql`
  mutation setBillingAddressOnCart($input: SetBillingAddressOnCartInput) {
    setBillingAddressOnCart(input: $input) {
      cart {
        billing_address {
          address_dob
          address_gender
          prefix
          firstname
          lastname
          street
          city
          company
          region {
            code
            region_id
            label
          }
          postcode
          telephone
          country {
            code
            label
          }
        }
        prices {
          subtotal_excluding_tax {
            value
          },
          subtotal_including_tax {
            value
          },
          applied_taxes {
            amount {
              value
            },
            label
          }
          discounts {
            amount {
              value
            },
            label
          }
          grand_total {
            value
          }
        }
      }
    }
  }
`;
