import gql from 'graphql-tag';

export default gql`
  query getProductDetailsReviews($url: String!) {
    route(url: $url) {
      ... on ProductInterface {
        id
        rating_summary
        review_count
      }
    }
  }
`;
