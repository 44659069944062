
















import {
  defineComponent,
} from '@nuxtjs/composition-api';

import {usePageBuilder} from "~/composables/Palmers";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";
export default defineComponent({
  name: 'PageBuilder',
  components: {
    PageBuilderElement: () => import("~/components/Palmers/PageBuilder/PageBuilderElement.vue"),
    LoadWhenVisible,
    PageBuilderStyles: () => import("~/components/Palmers/PageBuilder/PageBuilderStyles.vue")
  },
  props: {
    content: {
      type: String,
      require: true
    },
    id: {
      type: [String, Number]
    },
    updated: {
      type: [String, Number]
    }
  },
  setup(props) {
    const {getPageBuilderData} = usePageBuilder();

    const pageBuilder = getPageBuilderData(props.content);

    return {
      pageBuilder
    }
  },
  head: {
    link: [
      {rel: 'stylesheet', body: true, type: 'text/css', href: '/page_builder/css/generated.css', hid: 'page_builder'}
    ]
  }
});
