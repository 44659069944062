import productItem from "./productItem";

export default `
items_count
        sharing_code
        updated_at
        items_v2 {
          items {
            id
            quantity
            description
            added_at
            product ${productItem}
            ... on ConfigurableWishlistItem {
              configurable_options {
                configurable_product_option_uid
                configurable_product_option_value_uid
              }
            }
          }
          page_info {
            page_size
            current_page
          }
        }
`

