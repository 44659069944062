import gql from "graphql-tag";
import wishlistEntryData from "./const/wishlistEntryData";

export default gql`
  mutation guestMergeWishlist($customerId: ID!, $customerToken: ID!){
    guestMergeWishlist(customerId: $customerId, customerToken: $customerToken ) {
      wishlist {
        ${wishlistEntryData}
      }
    }
  }
`;
