const items = `items {
        label_id
        product_id
        position
        name
        txt
        image
        size
        style
        tooltip_settings {
          status
          color
          text_color
          text
        }
        is_visible
        customer_group_ids
        redirect_url
        alt_tag
      }`;

export default `
  am_label_data {
  list {${items}}
  pdp {${items}}
  }
`;
