import gql from 'graphql-tag';
import cartItemsUpdate from "./const/cartItemsUpdate";

export default gql`
  mutation removeItemFromCart($input: RemoveItemFromCartInput) {
    removeItemFromCart(input: $input) {
      cart {
        ${cartItemsUpdate}
      }
    }
  }
`;
