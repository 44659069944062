import gql from 'graphql-tag';

export default gql`
query {
    socials {
        enabled_socials{
            items {
                type
                label
                url
            }
        }
        enabled_labels
        social_buttons_type
    }
}
`;

