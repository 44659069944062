import {computed, reactive} from "@nuxtjs/composition-api";
import {PalmersProductInterface} from "~/modules/palmers/GraphQl/types";

const addToCartPopupData = reactive({
  visible: <Boolean>false,
  product: <PalmersProductInterface>{},
  configuration: []
});

const useAddToCartPopup = () => {
  const product = computed(() => addToCartPopupData.product);
  const visible = computed(() => addToCartPopupData.visible);
  const configuration = computed(() => addToCartPopupData.configuration);

  const setProduct = (product) => {
    addToCartPopupData.product = product;
  }

  const setConfiguration= (configuration) => {
    addToCartPopupData.configuration = configuration;
  }

  const setVisible = (visible) => {
    addToCartPopupData.visible = visible;
  }

  const activateModal = (product, configuration) => {
    setConfiguration(configuration);
    setProduct(product);
    setVisible(true);
  }

  const deactivateModal = () => {
    setConfiguration([]);
    setProduct({});
    setVisible(false);
  }

  return {
    product,
    setProduct,
    setVisible,
    activateModal,
    deactivateModal,
    visible,
    configuration
  }
};

export default useAddToCartPopup;
