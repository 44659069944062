import gql from 'graphql-tag';

export default gql`
  query getAffiliate($pageType: String = "all") {
    affiliate(pageType: $pageType) {
        scripts {
            head
            bodybegin
            bodyend
        }
    }
  }
`;
