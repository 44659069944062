import gql from 'graphql-tag';

export default gql`{
    getStoreLocations {
        id
        city
        zip
        address
        phone
        schedule
        stores
        lat
        lng
    }
}`;
