import gql from 'graphql-tag';
export default gql`
  query {
  getJobs {
    group_name
    jobs {
      area
      identities
      location
      pdf
      position
    }
  }
}
`;
