import gql from 'graphql-tag';
import labels from "./const/labels";

export default gql`
  query getProductLabelsOutOfStock($url: String!) {
    route(url: $url) {
      ... on ProductInterface {
        id
        ${labels}
      }
    }
  }
`;
