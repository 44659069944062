import gql from 'graphql-tag';

export default gql`
  query routeResolver($url: String!, $updated: String) {
    route(url: $url, updated: $updated) {
      relative_url
      redirect_code
      __typename
      type
      ... on ProductInterface {
        status
        id
        sku
        meta_title
        meta_description
        meta_keyword
        updated_at
        type_id
        stock_status
        mw_seo_markup {
          social_markup
          rich_snippets {
            website
            seller
            product
          }
        }
        product_seo_name
        meta_robots
        mw_canonical_url {
          url
          code
        }
        mw_hreflangs {
          items {
            url
            code
          }
        }
        cross_domain_store
      }
      ... on CmsPage {
        page_id
        identifier
        meta_title
        meta_description
        meta_keywords
        content_heading
        content
        title
        update_time
        mw_seo_markup {
          social_markup
          rich_snippets {
            website
            seller
            webpage
          }
        }
        meta_robots
        mw_canonical_url {
          url
          code
        }
        mw_hreflangs {
          items {
            url
            code
          }
        }
      }
      ... on CategoryInterface {
        id
        uid
        level
        display_mode
        meta_title
        meta_description
        meta_keywords
        updated_at
        description
        default_sort_by
        mw_seo_markup {
          social_markup
          rich_snippets {
            website
            seller
          }
        }
        meta_robots
        mw_canonical_url {
          url
          code
        }
        mw_hreflangs {
          items {
            url
            code
          }
        }
        category_seo_name
      }
    }
    amBlogPost(urlKey: $url) {
      title
      post_id
      updated: updated_at
      meta_title
      meta_description
      full_content
      post_thumbnail
      created_at
    }
  }
`;
