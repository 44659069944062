import {computed, ComputedRef, reactive, useRouter, watch} from '@nuxtjs/composition-api';
import {AvailableShippingMethod, Cart, Country, Region, CustomerAddress} from "~/modules/GraphQL/types";
import {PalmersCustomerAddress} from "~/modules/palmers/GraphQl/types";
import useUser from "~/modules/customer/composables/useUser";
import {useUrl} from "~/composables/Palmers";
import {addressFromApiToForm} from "~/helpers/checkout/address";

const checkoutData = reactive({
  cart: <ComputedRef<Cart>>{},
  countries: <Country[]>[],
  country: <Country>null,
  userAddresses: <CustomerAddress[] | PalmersCustomerAddress[]>[],
  shippingMethods: <AvailableShippingMethod[]>[],
  regions: <Region[]>[],
  region: <Region>null,
  loading: true
});

const useCheckout = () => {
  const {isAuthenticated} = useUser();
  const router = useRouter();
  const {getPageUrl} = useUrl();

  const countries = computed(() => checkoutData.countries);
  const userAddresses = computed(() => checkoutData.userAddresses);
  const cart = computed(() => checkoutData.cart);
  const country = computed(() => checkoutData.country);
  const region = computed(() => checkoutData.region);
  const regions = computed(() => checkoutData.regions);
  const shippingMethods = computed(() => checkoutData.shippingMethods);
  const loading = computed(() => checkoutData.loading);

  const getShippingAddress = () => {
    const shippingAddress = cart.value?.shipping_addresses?.[0];

    if(!shippingAddress && isAuthenticated.value && userAddresses.value.length) {
      // @ts-ignore
      const userAddress = userAddresses.value.find((address) => address.default_shipping);

      if(!userAddress) return false;
      return userAddress as any;
    }

    return shippingAddress;
  }

  const getBillingAddress = () => {
    const billingAddress = cart.value?.billing_address;

    if(!billingAddress && isAuthenticated.value && userAddresses.value.length) {
      // @ts-ignore
      const userAddress = userAddresses.value.find((address) => address.default_billing);
      if(!userAddress) return false;
      return userAddress as any;
    }

    return billingAddress;
  }

  const setCart = (cart) => {
    checkoutData.cart = cart;
  }

  const setCountries = (countries) => {
    checkoutData.countries = countries;

    const shippingAddress = getShippingAddress();

    if(!country.value && shippingAddress?.country?.code && countries.length) {
      setCountry(countries.filter((country) => country.id === shippingAddress?.country?.code)?.[0] || null)
    }
  }

  const setRegions = (regions) => {
    checkoutData.regions = regions;

    const shippingAddress = getShippingAddress();

    if(!region.value && shippingAddress?.region?.code && regions.length) {
      setRegion(regions.filter((region) => region.code === shippingAddress?.region?.code)?.[0] || null)
    }else if(shippingAddress?.region?.code) {
      setRegion(shippingAddress.region.code);
    }
  }

  const setCountry = (country) => {
    checkoutData.country = country;

    if(country?.available_regions && country?.available_regions.length) {
      setRegions(country.available_regions);
    }
  }

  const setRegion = (region) => {
    checkoutData.region = region;
  }

  const setUserAddresses = (userAddresses) => {
    checkoutData.userAddresses = userAddresses;
  }

  const setShippingMethods = (shippingMethods) => {
    checkoutData.shippingMethods = shippingMethods;
  }

  const setAllData = (response) => {
    for(let r = 0; r < response.length; r++) {
      if(response[r]?.[0]?.__typename === "Country") {
        setCountries(response[r]);
      }
      if(response[r]?.[0]?.__typename === "AvailableShippingMethod") {
        setShippingMethods(response[r]);
      }
      if(response[r]?.[0]?.__typename === "CustomerAddress") {
        setUserAddresses(response[r]);
      }
    }

    checkoutData.loading = false;
  }

  watch(cart, () => {
    const shippingAddress = getShippingAddress();

    if(shippingAddress?.available_shipping_methods) {
      setShippingMethods(shippingAddress?.available_shipping_methods);
    }

    if(shippingAddress?.country?.code && countries.value.length) {
      setCountries(countries.value);
    }
  })

  const isShippingFilled = () => {
    const shippingAddress = getShippingAddress();

    if(!shippingAddress) return false;

    //@ts-ignore
    const {firstname, lastname, street, city, postcode, region, telephone, country, country_code, apartment} = shippingAddress;

    return (
      firstname && firstname !== 'placeholder'
      && lastname && lastname !== 'placeholder'
      && (street.length && street?.[0] !== 'placeholder' || street.length && apartment)
      && city && city !== 'placeholder'
      && postcode && postcode !== 'placeholder'
      && telephone && telephone !== 'placeholder'
      && (region && region?.code || region)
      && (country && country?.code || country_code)
    )
  }

  const goToCheckout = async () => {
    if (isAuthenticated.value) {
      await router.push(getPageUrl({name: 'shipping-methods'}));
    } else {
      if(isShippingFilled()) {
        await router.push(getPageUrl({name: 'shipping-methods'}));
      }else {
        await router.push(getPageUrl({name: 'user-account'}));
      }
    }
  };

  const isSameAddresses = (address1, address2) => {
    if(!address1 || !address2) return true;
    return (
      address1?.city === address2?.city
      && address1?.firstname === address2?.firstname
      && address1?.lastname === address2?.lastname
      && address1?.street?.[0] === address2?.street?.[0]
      && address1?.street?.[1] === address2?.street?.[1]
      && address1?.street?.[2] === address2?.street?.[2]
      && address1?.postcode === address2?.postcode
      && address1?.region?.code === address2?.region?.code
      && address1?.country?.code === address2?.country?.code
    )
  }

  const getCountryRegionLabel = (address) => {
    const country = countries.value.filter((country) => country.id === address?.country_code)?.[0] || null;
    let region;

    if(country && country?.available_regions) {
      region = country?.available_regions.filter((region) => region.code === address?.region)?.[0] || null
    }

    return {
      country: country?.full_name_locale,
      region: region?.name || address?.region?.code
    }
  }



  const formatAddressToComma = (address) => {
    if(!address) return '';

    const addressFormatted = Array.isArray(address.street) ? addressFromApiToForm(address) : address;
    const countryRegionLabel = getCountryRegionLabel(addressFormatted);
    // @ts-ignore
    return `${addressFormatted.firstname} ${addressFormatted.lastname}, ${addressFormatted.street} ${addressFormatted.apartment}, ${countryRegionLabel.region}, ${countryRegionLabel.country}, ${addressFormatted.postcode}`.replaceAll('undefined,', '');
  }

  return {
    setCart,
    setCountries,
    setCountry,
    setUserAddresses,
    setAllData,
    setShippingMethods,
    setRegion,
    setRegions,
    regions,
    region,
    countries,
    userAddresses,
    cart,
    country,
    shippingMethods,
    getShippingAddress,
    getBillingAddress,
    isShippingFilled,
    goToCheckout,
    isSameAddresses,
    formatAddressToComma,
    loading
  }
};

export default useCheckout;
