import gql from 'graphql-tag';
import wishlistEntryData from "./const/wishlistEntryData";

export default gql`
  {
    customer {
      wishlists {
        ${wishlistEntryData}
      }
    }
  }
`;
