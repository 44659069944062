import gql from 'graphql-tag';
/** GraphQL Query that fetches store configuration from the API */
export default gql`
  query storeConfig {
    storeConfig {
        store_code,
        default_title,
        store_name,
        default_display_currency_code,
        locale,
        header_logo_src,
        logo_width,
        logo_height,
        logo_alt,

        cookie_pro_general_active,
        cookie_pro_general_script_url,
        cookie_pro_general_sdk_script_url,
        cookie_pro_general_cookie_pro_id,

        weltpixel_googletagmanager_general_enable,
        weltpixel_googletagmanager_general_google_tag_id,
        weltpixel_googletagmanager_general_track_stockstatus,
        weltpixel_googletagmanager_general_track_saleproduct,
        weltpixel_googletagmanager_general_track_reviewscount,
        weltpixel_googletagmanager_general_track_reviewsscore,

        design_head_default_title,
        design_head_title_prefix,
        design_head_title_suffix,
        design_head_default_description,
        design_head_default_keywords

        design_theme_theme_id
    }
  }
`;
