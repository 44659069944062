import gql from 'graphql-tag';
import cart from "./const/cart";

export default gql`
  query customerCart {
    customerCart {
      ${cart}
    }
  }
`;
