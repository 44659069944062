import {useContext} from "@nuxtjs/composition-api";

const useStoreLocator = () => {
  const context = useContext();

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180)
  }

  const getDistance = (location1, location2) => {
    let distance = getDistanceFromLatLon(location1, location2);
    const km = context.i18n.t('km');
    const m = context.i18n.t('m');

    //@ts-ignore
    if (parseInt(distance) === 0) {
      //@ts-ignore
      distance = Math.round(distance * 1000) + m;
    } else {
      //@ts-ignore
      distance = Math.round(distance) + km;
    }

    return distance;
  }

  const getDistanceFromLatLon = (location1, location2) => {
    const lat1 = location1.lat;
    const lon1 = location1.lng;
    const lat2 = location2.lat;
    const lon2 = location2.lng;
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  }

  const getLang = () => {
    return context.i18n.localeProperties.iso.split('_')[0];
  }


  return {
    getDistance,
    getDistanceFromLatLon,
    getLang
  }
};

export default useStoreLocator;
