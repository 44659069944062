import gql from 'graphql-tag';
import wishlistEntryData from "./const/wishlistEntryData";

export default gql`
  mutation addProductsToWishlist($id: ID!, $items: [WishlistItemInput!]!) {
    addProductsToWishlist(
      wishlistId: $id,
      wishlistItems: $items
    ) {
      wishlist {
        ${wishlistEntryData}
      }
    }
  }
`;
