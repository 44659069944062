import gql from 'graphql-tag';
import cartItemsUpdate from "./const/cartItemsUpdate";

export default gql`
  mutation addProductsToCart($cartId: String!, $cartItems: [CartItemInput!]!) {
    addProductsToCart(cartId,: $cartId, cartItems,: $cartItems) {
      cart {
        ${cartItemsUpdate}
      }
      user_errors {
        code
        message
      }
    }
  }
`;
