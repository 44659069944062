import wishlistEntryData from "./const/wishlistEntryData";
import gql from "graphql-tag";

export default gql`
  mutation guestAddProductsToWishlist($customerId: ID!, $wishlistItems: [WishlistItemInput!]!) {
    guestAddProductsToWishlist(customerId: $customerId, wishlistItems: $wishlistItems) {
      wishlist {
        ${wishlistEntryData}
      }
    }
  }
`;
