import {useContext, useRouter} from "@nuxtjs/composition-api";
import {useProduct, useUiHelpers} from "~/composables";

const useUrl = () => {
  const {$config, localePath, i18n} = useContext();
  const router = useRouter();
  const {getCatLink} = useUiHelpers();
  const {getProductPath} = useProduct();

  const replaceDoubleSlash = (url) => {
    return url.replaceAll('//', '/');
  }

  const getBaseUrl = () => {
    let baseUrl = i18n.__baseUrl;

    if(baseUrl[baseUrl.length - 1] !== '/') {
      baseUrl += '/';
    }

    return baseUrl;
  }

  const urlFixer = (url) => {
    const regex = /^(?!.*\/$)(?!.*api)(?!.*\.(?:png|jpe?g|gif|css|js|html)$)(?!.*\?.*$).+$/;
    let urlFixes = url;
    urlFixes = replaceDoubleSlash(urlFixes);

    if(urlFixes[urlFixes.length - 1] !== '/' && regex.test(urlFixes)) {
      urlFixes += '/';
    }

    urlFixes = decodeURIComponent(urlFixes);

    return urlFixes;
  }

  const urlBuilder = (url) => {
    const baseUrl = i18n['__baseUrl'];
    const path = localePath(url);
    return urlFixer(baseUrl + localePath(path));
  }

  const itemUrlBuilder = (url) => {
    const baseUrl = i18n['__baseUrl'];
    const path = localePath(url);
    const html = '.html';
    url = baseUrl + path + url + html;
    return url;
  }

  const getProductUrl = (product) => {
    const productUrl = getProductPath(product);
    return urlFixer(localePath(productUrl));
  }

  const getCategoryUrl = (category) => {
    const categoryLink = getCatLink(category);
    return urlFixer(localePath(categoryLink));
  }

  const getPageUrl = (pageUrl, locale = true) => {
    if (typeof pageUrl === 'string' && pageUrl.indexOf('http') > -1) {
      return pageUrl;
    }

    if(typeof pageUrl !== "object") {
      if (pageUrl === '/' || !pageUrl) return urlFixer(localePath({name: 'home'}));

      if (pageUrl[0] !== '/') pageUrl = '/' + pageUrl;
    }

    return urlFixer(locale ? localePath(pageUrl) : router.match(pageUrl).fullPath);
  }

  const getProductMediaUrl = (path) => {
    const url = $config.catalogProductMediaUrl;
    path = replaceDoubleSlash(path);
    return url + path;
  }

  const getCategoryMediaUrl = (path) => {
    const url = $config.catalogCategoryMediaUrl;
    path = replaceDoubleSlash(path);
    return url + path;
  }

  const getMediaUrl = (path) => {
    const url = $config.magentoMediaUrl;
    path = replaceDoubleSlash(path.replace('/media',''));
    return url + path;
  }

  const getSwatchUrl = (path) => {
    const url = $config.swatchMediaUrl;
    path = replaceDoubleSlash(path.replace('/media',''));
    return url + path;
  }

  const getBaseUrlPath = (path) => {
    const baseUrl = getBaseUrl();

    if(path[0] === '/') {
      path = path.substring(1);
    }

    return `${baseUrl}${path}`;
  }

  return {
    urlFixer,
    urlBuilder,
    getProductUrl,
    getCategoryUrl,
    getPageUrl,
    itemUrlBuilder,
    getProductMediaUrl,
    getCategoryMediaUrl,
    getMediaUrl,
    getSwatchUrl,
    getBaseUrl,
    getBaseUrlPath
  }
};

export default useUrl;
