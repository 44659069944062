export default `{
  __typename
  id
  uid
  sku
  name
  pa_series
  pa_displayname
  mouseover_image {
       url
       label
  }
  image {
       url
       label
  }
  ... on SimpleProduct {
    diamond_price
  }
  special_from_date
  special_to_date
  stock_status
  categories{
    name
    uid
    id
  }
  only_x_left_in_stock
  thumbnail {
    url
    position
    disabled
    label
  }
  url_key
  url_rewrites {
    url
  }
  price_range {
    maximum_price {
      final_price {
        currency
        value
      }
      regular_price {
        currency
        value
      }
    }
    minimum_price {
      final_price {
        currency
        value
      }
      regular_price {
        currency
        value
      }
    }
  }
  ... on ConfigurableProduct {
    variants {
      product {
        id
        only_x_left_in_stock
        stock_status
        diamond_price
        color
        pa_size
        mouseover_image {
                  url
                  label
                }
        muenze_boxfarbe
        muenze_schleife
        image {
          url
          label
        }
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        uid
        sku
      }
    }
    configurable_options {
      id
      uid
      attribute_id
      attribute_id_v2
      attribute_uid
      attribute_code
      label
      position
      use_default
      values {
        value_index
        uid
        label
        default_label
        store_label
        use_default_value
        swatch_data {
          value
        }
      }
      product_id
    }
  }
  ... on GroupedProduct {
    items {
      product {
        sku
      }
    }
  }
}`;
