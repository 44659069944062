import gql from 'graphql-tag';

export default gql`
  query getProductFiltersByCategory($search: String = "", $filter: ProductAttributeFilterInput, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, sort: $sort) {
      items {
        id
        categories{
          id
        }
      }
      aggregations {
        label
        count
        attribute_code
        options {
          count
          label
          value
          swatch_data {
            value
            __typename
          }
          __typename
        }
        position
        __typename
      }
      __typename
    }
  }
`;


