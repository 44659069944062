/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
import labels from "./const/labels";
import gql from 'graphql-tag';

export default gql`
  query getFacetData($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput, $updated: String) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort, updated: $updated) {
      items {
        id
        categories {
          id
        }
        ${labels}
      }
    }
  }
`;
