import labels from "./const/labels";
import gql from 'graphql-tag';

export default gql`
  query {
    bestSellers {
        items {
          id
          categories {
            id
          }
          ${labels}
        }
    }
  }
`;
