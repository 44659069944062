import { isEqual } from 'lodash-es';
import type { CustomerAddress, CartAddressInterface } from '~/modules/GraphQL/types';
import {PalmersCustomerAddress} from "~/modules/palmers/GraphQl/types";

export const formatAddressReturnToData = (address: PalmersCustomerAddress) => ({
  address_gender: address.address_gender,
  prefix: address.prefix,
  firstname: address.firstname,
  lastname: address.lastname,
  street: address.street[0],
  apartment: address.street[1],
  city: address.city,
  region: address.region.code,
  country_code: address.country.code,
  postcode: address.postcode,
  telephone: address.telephone,
  address_dob: address.address_dob
});

/**
 * Converts addresses that were:
 * * added to the logged in user's account
 * * saved in the cart (eg. completed the shipping address step and saved in the Checkout page, then went to some other page)
 * into the form data format used by Billing and Shipping forms in the Checkout page
 */
export const addressFromApiToForm = (address: PalmersCustomerAddress) : CheckoutAddressForm => ({
  address_gender: address.address_gender,
  address_name: address.address_name,
  firstname: address.firstname,
  lastname: address.lastname,
  street: address.street?.[0],
  apartment: address.street?.[1],
  city: address.city,
  region: (address as CustomerAddress)?.region?.region_code || (address as CartAddressInterface).region.code,
  country_code: (address as CustomerAddress)?.country_code || (address as CartAddressInterface).country.code,
  postcode: address.postcode,
  telephone: address.telephone,
  extra: address.street?.[3],
  neighborhood: address.street?.[2],
  prefix: address.prefix,
  address_dob: address.address_dob
});

export const fromAddressAccountToAddressShipping = (address: CheckoutAddressForm) : CheckoutAddressForm => ({
  address_gender: address.address_gender,
  address_name: address.address_name,
  firstname: address.firstname,
  lastname: address.lastname,
  street: address.street,
  apartment: address.apartment,
  city: address.city,
  region: address.region,
  country_code: address.country_code,
  postcode: address.postcode,
  telephone: address.telephone,
  extra: address.extra,
  neighborhood: address.neighborhood,
  prefix: address.prefix,
  address_dob: address.address_dob
});

export const fromAddressAccountToAddressShippingData = (address: CheckoutAddressForm) : PalmersCustomerAddress => ({
  address_gender: address.address_gender,
  address_name: address.address_name,
  firstname: address.firstname,
  lastname: address.lastname,
  street: [
    address?.street,
    address?.apartment,
    address?.neighborhood,
    address?.extra
  ],
  city: address.city,
  region: {
    code: address.region
  },
  country: {
    code: address.country_code,
    label: ''
  },
  postcode: address.postcode,
  telephone: address.telephone,
  prefix: address.prefix,
  address_dob: address.address_dob
});

export interface CheckoutAddressForm {
  address_gender: number;
  address_name: string;
  firstname: string;
  lastname: string;
  street: string;
  apartment: string;
  city: string;
  region: string;
  country_code: string;
  neighborhood: string,
  postcode: string;
  telephone: string;
  extra: string;
  prefix: string;
  address_dob: string;
}

export const getInitialCheckoutAddressForm = () : CheckoutAddressForm => ({
  address_gender: 0,
  address_name: '',
  firstname: '',
  lastname: '',
  street: '',
  apartment: '',
  city: '',
  region: '',
  country_code: '',
  neighborhood: '',
  postcode: '',
  telephone: '',
  extra: '',
  prefix: '',
  address_dob: ''
});

/**
 * Try to find an address from the user's saved addresses that exactly matches the address that is bound to a cart.
 *
 * `useShipping().save()``sends an addressId to Magento to set the shipping address on the cart,
 * but when you download the cart after that - the cart's endpoint response doesn't contain that addressId, just the address fields (street etc.)
 * So the only choice left is to try to compare the fields of the addresses.
 *
 * This function exists because if a user returns to a cart whose shipping address was set before, we want the user address to be highlighted in the SfAddressPicker component.
 *
 * @param customerAddresses The addresses saved in a user's account
 * @param cartAddress The address that is bound to the cart, @see Cart["billing_address"] Cart["shipping_addresses"]
 *
 */
export const findUserAddressIdenticalToSavedCartAddress = (
  customerAddresses: CustomerAddress[] | null,
  cartAddress: CartAddressInterface,
) : CustomerAddress => customerAddresses?.find(
  //@ts-ignore
  (userAddress) => isEqual(addressFromApiToForm(userAddress), addressFromApiToForm(cartAddress)),
) || null;
