export default `
  id
  email
  applied_coupons {
    code
  }
  prices {
    subtotal_excluding_tax {
      value
    },
    subtotal_including_tax {
      value
    },
    applied_taxes {
      amount {
        value
      },
      label
    }
    discounts {
      amount {
        value
      },
      label
    }
    grand_total {
      value
    }
  }
  items {
    uid
    product {
      thumbnail {
        url
        label
      }
      uid
      __typename
      sku
      name
      stock_status
      only_x_left_in_stock
      url_key
      url_rewrites {
        url
      }
      price_range {
        maximum_price {
          final_price {
            currency
            value
          }
          regular_price {
            currency
            value
          }
        }
        minimum_price {
          final_price {
            currency
            value
          }
          regular_price {
            currency
            value
          }
        }
      }
      categories {
        uid
        name
      }
      ... on ConfigurableProduct {
      variants {
              product {
                only_x_left_in_stock
                stock_status
                color
                pa_size
                mouseover_image {
                  url
                  label
                }
                muenze_boxfarbe
                muenze_schleife
                image {
                  url
                  label
                }
                price_range {
            maximum_price {
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
            minimum_price {
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
          }
                uid
                sku
              }
            }
        configurable_options {
          id
              uid
              attribute_id
              attribute_id_v2
              attribute_uid
              attribute_code
              label
              position
              use_default
              values {
                value_index
                uid
                label
                default_label
                store_label
                use_default_value
                swatch_data {
                  value
                }
              }
              product_id
        }
      }
    }
    prices {
      row_total {
        value
      }
      row_total_including_tax {
        value
      }
      total_item_discount {
        value
      }
    }
    quantity
    ... on ConfigurableCartItem {
      configurable_options {
        configurable_product_option_uid
        option_label
        configurable_product_option_value_uid
        value_label
      }
      configured_variant {
        only_x_left_in_stock
        stock_status
        sku
        thumbnail {
          url
        }
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
      }
    }
  }
  total_quantity
  shipping_addresses {
    address_dob
    address_gender
    prefix
    firstname
    lastname
    street
    city
    company
    region {
      code
      region_id
      label
    }
    postcode
    telephone
    country {
      code
      label
    }
    available_shipping_methods {
            amount {
              currency
              value
            }
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_incl_tax {
              currency
              value
            }
          }
    selected_shipping_method {
      carrier_code
      carrier_title
      method_code
      method_title
      amount {
        value
        currency
      }
    }
  }
  billing_address {
    address_dob
    address_gender
    prefix
    firstname
    lastname
    street
    city
    company
    region {
      code
      region_id
      label
    }
    postcode
    telephone
    country {
      code
      label
    }
  }
  selected_payment_method {
      code
    }
`;
