import wishlistEntryData from "./const/wishlistEntryData";
import gql from "graphql-tag";

export default gql`
  mutation guestRemoveProductsFromWishlist($customerId: ID!, $wishlistItemsIds: [ID!]!) {
    guestRemoveProductsFromWishlist(customerId: $customerId, wishlistItemsIds: $wishlistItemsIds) {
      wishlist {
        ${wishlistEntryData}
      }
    }
  }
`;
