




import {defineComponent} from '@nuxtjs/composition-api';

import PictureItem from "~/components/Palmers/BaseComponents/Picture/PictureItem.vue";
import {usePageBuilder} from "~/composables/Palmers";
import PageBuilder from "~/components/Palmers/PageBuilder/PageBuilder.vue";
export default defineComponent({
  name: 'StaticBlock',
  components: {
    PictureItem,
    PageBuilder
  },
  props: {
    block: {
      type: [Object, Boolean, Array],
      require: true
    }
  },
  setup() {
    const {isPageBuilder} = usePageBuilder();

    return {
      isPageBuilder
    }
  }
});
