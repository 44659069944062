import gql from 'graphql-tag';
import cart from "./const/cart";

export default gql`
  query cart($cartId: String!) {
    cart(cart_id:$cartId) {
      ${cart}
    }
  }
`;
