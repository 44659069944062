import config from 'static/page_builder/config.json';

const usePageBuilder = () => {
  const isPageBuilder = (content) => {
    const regex = /\[mgz_pagebuilder\]([\s\S]*?)\[\/mgz_pagebuilder\]/gi;
    return regex.test(content);
  }

  const getPageBuilderData = (content) => {
    let data = content;
    data = data.replace('[mgz_pagebuilder]', '');
    data = data.replace('[/mgz_pagebuilder]', '');
    return JSON.parse(data);
  }

  const getLink = (linkMagezon) => {
    if(!linkMagezon) {
      return {
        url: null,
        title: null,
        componentType: 'a'
      }
    }

    const regex = /url="([^"]*)"[\s\S]*?title="([^"]*)"[\s\S]*?nofollow=([^"\s]+)[\s\S]*?blank=([^"\s]+)/;

    const match = linkMagezon.match(regex);

    if (match) {
      const [, url, title, nofollow, blank] = match;

      const componentType = (url.indexOf('http') > -1) ? 'a' : 'nuxt-link'

      return {
        url,
        title,
        nofollow,
        blank: blank.replace('}}', ''),
        componentType
      }
    }

    return {}
  }

  const appendCustomCss = (css) => {
    const head = document.head || document.getElementsByTagName('head')[0],
      style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
  }

  return {
    isPageBuilder,
    getPageBuilderData,
    config: config,
    getLink,
    appendCustomCss
  }
};

export default usePageBuilder;
