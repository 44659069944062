import gql from 'graphql-tag';
import labels from "./const/labels";

export default gql`
  query getProductLabels($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 1, $updated: String) {
    products(search: $search, filter: $filter, pageSize: $pageSize, updated: $updated, mode: "product") {
      items {
        id
        ${labels}
      }
    }
  }
`;
