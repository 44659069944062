export default `
  query getCustomForm($form_id: Int){
    customform(formId: $form_id) {
      form_id
      title
      code
      success_url
      status
      created_at
      customer_group
      store_id
      send_notification
      send_to
      submit_button
      success_message
      form_json
      email_field
      form_title
      gdpr_enabled
      gdpr_text
      is_form_available
    }
  }
`;
